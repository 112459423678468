import { Box, Button, Chip, Grid, MenuItem, Popover, TextField, Typography } from '@material-ui/core';
import { FilterList } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { FILTER_ATTRS } from './constants';
import { LOCATION_TYPES } from '@/constants/locationTypes';

const volumeOptions = [
    {
        value: '0|100',
        label: '0 - 100',
    },
    {
        value: '100|200',
        label: '100 - 200',
    },
    {
        value: '200|300',
        label: '200 - 300',
    },
    {
        value: '300|400',
        label: '300 - 400',
    },
    {
        value: '400|500',
        label: '400 - 500',
    },
    {
        value: '500|9999999',
        label: '500 +',
    },
];

const distanceOptions = [
    {
        value: '0|5',
        label: '0 - 5',
    },
    {
        value: '5|10',
        label: '5 - 10',
    },
    {
        value: '10|25',
        label: '10 - 25',
    },
    {
        value: '25|50',
        label: '25 - 50',
    },
    {
        value: '50|100',
        label: '50 - 100',
    },
    {
        value: '100|9999999',
        label: '100 +',
    },
];

const scheduledOptions = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'Scheduled',
        label: 'Scheduled',
    },
    {
        value: 'Unscheduled',
        label: 'Unscheduled',
    },
];

const orderStatusOptions = [
    {
        value: 'All',
        label: 'All',
    },
    {
        value: 'pending',
        label: 'Pending',
    },
    {
        value: 'claimed',
        label: 'Claimed',
    },
    {
        value: 'open',
        label: 'Open',
    },
    {
        value: 'inProgress,routedInProgress',
        label: 'In Progress',
    },
    {
        value: 'complete,routedComplete',
        label: 'Complete',
    },
    {
        value: 'cancelled',
        label: 'Cancelled',
    },
    {
        value: 'incomplete,pending_confirmation,duplicate,processing',
        label: 'Incomplete',
    },
];

const middleMileOptions = [
    { value: 'ALL', label: 'All' },
    { value: 'NONE', label: 'No Status / Status Unknown' },
    { value: 'PENDING', label: 'Pending' },
    { value: 'SCHEDULED', label: 'Scheduled' },
    { value: 'IN_TRANSIT', label: 'In Transit' },
    { value: 'ARRIVED', label: 'Arrived' },
    { value: 'CANCELLED', label: 'Cancelled' },
];

const wh_statuses = [
    { value: 'RECEIVED', label: 'Received' },
    { value: 'NOT_DELIVERED', label: 'Not Received' },
];

const order_types = [
    { value: 'delivery', label: 'Delivery' },
    { value: 'return', label: 'Return' },
    { value: 'exchange', label: 'Exchange' },
];

const FilterPopover = ({ applyFilters, userType, locations, shippers, manufacturersList, searchParams, user_id }) => {
    const [filters, setFilter] = useState({});
    const [anchor, setAnchor] = useState(null);
    const { service_levels } = useClientUser();

    const serviceTypes = useMemo(() => {
        return [
            ...Object.entries(LOCATION_TYPES).map(([value, label]) => ({ label, value })),
            ...service_levels.map(({ service_level }) => ({ label: service_level, value: service_level })),
        ];
    }, [service_levels]);

    const [
        [
            startDate,
            endDate,
            schedDelStart,
            schedDelEnd,
            estShipStart,
            estShipEnd,
            actualReceivedStart,
            actualReceivedEnd,
            warehouseStartDate,
            warehouseEndDate,
            warehouseStatus,
            manufacturer,
            orderType,
            ownerChip,
            pickup,
            dropoff,
            volume,
            distance,
            scheduledStatus,
            shipper,
            middleMileStatus,
            serviceType,
            locationType,
            orderStatus,
        ],
        [
            setStartDate,
            setEndDate,
            setSchedDelStart,
            setSchedDelEnd,
            setEstShipStart,
            setEstShipEnd,
            setActualReceivedStart,
            setActualReceivedEnd,
            setWarehouseStartDate,
            setWarehouseEndDate,
            setWarehouseStatus,
            setManufacturer,
            setOrderType,
            setOwnerChip,
            setPickup,
            setDropoff,
            setVolume,
            setDistance,
            setScheduledStatus,
            setShipper,
            setMiddleMileStatus,
            setServiceType,
            setLocationType,
            setOrderStatus,
        ],
    ] = useMemo(() => {
        const tuples = FILTER_ATTRS.map((attr) => [
            filters[attr] || searchParams.get(attr),
            (val) => {
                setFilter((prev) => {
                    return { ...prev, [attr]: val };
                });
            },
        ]);

        return [tuples.map(([getters]) => getters), tuples.map(([, setters]) => setters)];
    }, [filters]);

    const { circles } = useClientUser();

    const open = !!anchor;
    const id = open ? 'myorders-popover-filter' : undefined;

    const handleClearFilters = () => {
        setFilter({});
        applyFilters(
            Object.fromEntries(
                FILTER_ATTRS.map((attr) => {
                    return [attr, null];
                })
            )
        );
    };

    const handleApplyFilters = () => {
        applyFilters(
            Object.fromEntries(
                FILTER_ATTRS.map((attr) => {
                    return [attr, filters[attr]];
                })
            )
        );
    };

    const OwnerChip = ({ owner }) => {
        return (
            <Grid item className="pl-3">
                <Chip
                    label={owner}
                    color="primary"
                    variant={(ownerChip || 'All') === owner ? 'default' : 'outlined'}
                    onClick={(e) => setOwnerChip(e.target.innerText)}
                />
            </Grid>
        );
    };

    const PopoverSelect = ({ label, value, onChange, options = [] }) => {
        return (
            <>
                <Grid item xs={12}>
                    <Typography variant="h3">{label}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        style={{ width: '300px', maxHeight: '50px' }}
                        variant="outlined"
                        value={value}
                        onChange={onChange}
                    >
                        {options.map((option) => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </>
        );
    };

    return (
        <>
            <Button
                aria-describedby={id}
                variant="contained"
                color="primary"
                onClick={(e) => setAnchor(e.currentTarget)}
                css={css`
                    height: 100%;
                `}
            >
                <FilterList />
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchor}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box className="bg-white p-3" style={{ border: 'solid 1px #4C4C4C', borderRadius: '4px' }}>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid container className="h100" alignContent="center" style={{ width: 'auto' }}>
                            <Typography variant="h3">Owner</Typography>
                        </Grid>
                        <OwnerChip owner="All" />
                        <OwnerChip owner="Onward" />
                        <OwnerChip owner="Internal" />
                        <OwnerChip owner="Claimed" />
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Order Creation Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content', margin: '0 8px' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Scheduled Delivery Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={schedDelStart}
                                onChange={(e) => setSchedDelStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content', margin: '0 8px' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={schedDelEnd}
                                onChange={(e) => setSchedDelEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Estimated Shipment Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipStart}
                                onChange={(e) => setEstShipStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content', margin: '0 8px' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={estShipEnd}
                                onChange={(e) => setEstShipEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Estimated Warehouse Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseStartDate}
                                onChange={(e) => setWarehouseStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content', margin: '0 8px' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={warehouseEndDate}
                                onChange={(e) => setWarehouseEndDate(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" justifyContent="flex-start">
                        <Grid item xs={12}>
                            <Typography variant="h3">Actual Received Date</Typography>
                        </Grid>
                        <Grid item className="pr-3">
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedStart}
                                onChange={(e) => setActualReceivedStart(e.target.value)}
                            />
                        </Grid>
                        <Grid container className="pr-3 h100" style={{ width: 'auto' }} alignItems="center">
                            <Typography variant="h3" style={{ height: 'fit-content', margin: '0 8px' }}>
                                -
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                type="date"
                                variant="outlined"
                                size="small"
                                value={actualReceivedEnd}
                                onChange={(e) => setActualReceivedEnd(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Shipper"
                                value={shipper || 'All'}
                                onChange={(e) => setShipper(e.target.value)}
                                options={shippers || []}
                            />
                        </Grid>
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Manufacturer"
                                value={manufacturer}
                                onChange={(e) => setManufacturer(e.target.value)}
                                options={manufacturersList || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Order Type"
                                value={orderType}
                                onChange={(e) => setOrderType(e.target.value)}
                                options={order_types || []}
                            />
                        </Grid>
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Service Type"
                                value={serviceType || locationType || ''}
                                onChange={(e) => {
                                    if (LOCATION_TYPES[e.target.value]) {
                                        setLocationType(e.target.value);
                                        setServiceType(null);
                                    } else {
                                        setServiceType(e.target.value);
                                        setLocationType(null);
                                    }
                                }}
                                options={serviceTypes || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Order Status"
                                value={orderStatus || 'All'}
                                onChange={(e) => setOrderStatus(e.target.value === 'All' ? null : e.target.value)}
                                options={orderStatusOptions}
                            />
                        </Grid>
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Scheduled Delivery Status"
                                value={scheduledStatus || 'All'}
                                onChange={(e) => setScheduledStatus(e.target.value === 'All' ? null : e.target.value)}
                                options={scheduledOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Pick up"
                                value={pickup || ''}
                                onChange={(e) => setPickup(e.target.value)}
                                options={locations.pickupKeys || []}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Drop off"
                                value={dropoff || ''}
                                onChange={(e) => setDropoff(e.target.value)}
                                options={locations.dropoffKeys || []}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Cubic Feet"
                                value={volume || ''}
                                onChange={(e) => setVolume(e.target.value)}
                                options={volumeOptions}
                            />
                        </Grid>
                        <Grid item>
                            <PopoverSelect
                                label="Miles"
                                value={distance || ''}
                                onChange={(e) => setDistance(e.target.value)}
                                options={distanceOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} className="pb-3" wrap="nowrap">
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Warehouse Status"
                                value={warehouseStatus}
                                onChange={(e) => setWarehouseStatus(e.target.value)}
                                options={wh_statuses || []}
                            />
                        </Grid>
                        <Grid item className="me-3">
                            <PopoverSelect
                                label="Middle Mile Status"
                                value={middleMileStatus}
                                onChange={(e) => setMiddleMileStatus(e.target.value)}
                                options={middleMileOptions}
                            />
                        </Grid>
                    </Grid>
                    <Grid container xs={12} justifyContent="space-between">
                        <Grid item>
                            <Button onClick={handleClearFilters}>Clear</Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" onClick={handleApplyFilters}>
                                Filter
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </>
    );
};

export default FilterPopover;
