import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useClientUser } from '@/hooks';
import { OnwardTab, OnwardTabContainer } from '../Tabs';
import { Grid, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { PageTitle, PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { format } from 'date-fns';
import { asUTCDate } from '@/utilities/convertToISO';
import Table, { TableLoader } from '../Table';
import { useParams } from 'react-router';
import { GET_DRIVER_PAYMENT, UPDATE_DRIVER_PAYMENT } from './graphql';
import { useMutation, useQuery } from '@apollo/client';
import { captureException } from '@sentry/react';
import { driverPricing } from '../DriverPayments/algos';
import { GET_DRIVER_TARIFFS } from '../DriverPayments/graphql';
import { StatusBadge } from '../Accounting/Invoice/blocks';
import { startCase } from 'lodash';
import { colors, typography } from '@/styles';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

const Body1 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 500;
`;

const Body2 = styled.span`
    ${typography.sansSerif}

    font-size: 14px;
    font-weight: 700;
`;

const Total = styled.span`
    ${typography.sansSerif}

    font-size: 20px;
    font-weight: 700;
`;

const GridCard = styled((props) => <Grid container {...props} />)`
    background-color: white;
    border-radius: 5px;
    border: 1px solid ${colors.greys.border};
    padding: 1rem;
`;

const COLUMNS = [
    { label: 'Order', width: 2 },
    { label: 'Description', width: 4 },
    { label: 'Base', width: 2 },
    { label: 'Rate', width: 2 },
    { label: 'Total', width: 2 },
];

const DriverPayment = () => {
    const { user_id } = useClientUser();
    const { payment_id } = useParams();
    const [overrides, setOverrides] = useState(null);

    const { data, loading: paymentsLoading } = useQuery(GET_DRIVER_PAYMENT, {
        variables: { payment_id },
        onError: (e) => {
            console.error(e);
            captureException(e);
        },
    });

    const [updatePayment, { loading: updateLoading }] = useMutation(UPDATE_DRIVER_PAYMENT);

    const { data: tariffData, loading: tariffsLoading } = useQuery(GET_DRIVER_TARIFFS, {
        variables: { client_id: user_id },
    });

    const enriched = useMemo(() => {
        const payment = data?.driver_payment;
        if (!payment) return {};

        const tariffsByDriver = Object.fromEntries(
            (tariffData?.driver_tariffs || []).map((mapping) => [mapping.driver_id || 'DEFAULT', mapping])
        );
        const { rates, ...tariff } = (tariffsByDriver[payment.driver_id] || tariffsByDriver['DEFAULT'])?.tariff || {};
        const ratesByType = (rates || []).reduce((acc, rate) => {
            return {
                ...acc,
                [rate.type]: [...(acc[rate.type] || []), rate].sort((l, r) => l.min - r.min),
            };
        }, {});

        const { orders, routes } = (payment.mappings || []).reduce(
            (acc, mapping) => {
                if (mapping.route) {
                    return {
                        ...acc,
                        routes: [...acc.routes, mapping.route],
                    };
                } else if (mapping.order) {
                    return {
                        ...acc,
                        orders: [...acc.orders, mapping.order],
                    };
                }
                return acc;
            },
            { orders: [], routes: [] }
        );

        const pricingEnrichments = driverPricing(tariff.algo_type, {
            orders,
            routes,
            rates: ratesByType,
            date: payment.pay_period_start,
            overrides: overrides || payment.rate_overrides,
        });

        return {
            ...payment,
            ...pricingEnrichments,
            tariff,
        };
    }, [data, tariffData, overrides]);

    const onExport = () => {};

    const loading = paymentsLoading || updateLoading || tariffsLoading;
    const isModified = !!overrides;

    return (
        <Grid
            container
            direction="column"
            css={css`
                height: 100%;
                flex-wrap: nowrap;
            `}
        >
            <Grid
                container
                css={css`
                    padding: 1rem 2rem;
                    justify-content: space-between;
                    align-items: center;
                `}
            >
                <Grid
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <PageTitle>
                        {enriched.pay_period_start
                            ? format(new Date(enriched.pay_period_start), 'EEE, MMM d, yyyy')
                            : 'Driver Pay'}
                    </PageTitle>
                </Grid>
                <Grid item>
                    <SecondaryButton
                        disabled={loading}
                        onClick={onExport}
                        css={css`
                            margin-right: 1rem;
                        `}
                    >
                        Export
                    </SecondaryButton>
                </Grid>

                {isModified ? (
                    <Grid item>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                updatePayment({
                                    variables: {
                                        payment_id,
                                        update: { rate_overrides: overrides },
                                    },
                                }).then(() => {
                                    setOverrides(null);
                                })
                            }
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            Save Changes
                        </PrimaryButton>
                    </Grid>
                ) : null}

                {enriched.status === 'UNPAID' ? (
                    <Grid item>
                        <PrimaryButton
                            disabled={loading}
                            onClick={() =>
                                updatePayment({
                                    variables: {
                                        payment_id,
                                        update: { status: 'PAID' },
                                    },
                                })
                            }
                            css={css`
                                margin-right: 1rem;
                            `}
                        >
                            Mark as Paid
                        </PrimaryButton>
                    </Grid>
                ) : null}

                <Grid item>
                    <span
                        css={css`
                            border-radius: 5px;
                            border: 2px solid
                                ${enriched.status === 'UNPAID' ? colors.reds.primary : colors.greens.primary};
                            font-size: 14px;
                            font-weight: 700;
                            color: ${enriched.status === 'UNPAID' ? colors.reds.primary : colors.greens.primary};
                            padding: 4px 12px;
                        `}
                    >
                        {startCase((enriched.status || '').toLowerCase())}
                    </span>
                </Grid>
            </Grid>

            <Grid
                container
                css={css`
                    padding: 1rem 2rem;
                `}
            >
                <GridCard direction="column">
                    <Grid item>
                        <Body1>Driver: {enriched?.driver?.username}</Body1>
                    </Grid>
                    <Grid item>
                        <Body1>Tariff: {enriched?.tariff?.name}</Body1>
                    </Grid>
                    {enriched?.mileage ? (
                        <Grid item>
                            <Body1>Miles: {enriched?.mileage?.toFixed(2)}</Body1>
                        </Grid>
                    ) : null}
                </GridCard>
            </Grid>

            <Grid
                container
                css={css`
                    flex-grow: 1;
                    padding: 1rem 2rem;
                `}
            >
                {loading ? (
                    <TableLoader />
                ) : (
                    <GridCard direction="column">
                        <Grid container>
                            <Grid
                                item
                                css={css`
                                    width: 200px;
                                `}
                            >
                                <Body2>Order</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    flex-grow: 2;
                                `}
                            >
                                <Body2>Description</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 150px;
                                `}
                            >
                                <Body2>Base</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 150px;
                                `}
                            >
                                <Body2>Rate</Body2>
                            </Grid>
                            <Grid
                                item
                                css={css`
                                    width: 150px;
                                `}
                            >
                                <Body2>Total</Body2>
                            </Grid>
                        </Grid>
                        {(enriched.accessorials || []).map((accessorial, idx) => (
                            <Grid
                                key={idx}
                                container
                                css={css`
                                    height: 40px;
                                    border-top: 1px solid ${colors.greys.border};
                                    align-items: center;
                                `}
                            >
                                <Grid
                                    item
                                    css={css`
                                        width: 200px;
                                    `}
                                >
                                    <Body1>{accessorial.po_number || '-'}</Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        flex-grow: 2;
                                    `}
                                >
                                    <Body1>{accessorial.type || '-'}</Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        width: 150px;
                                    `}
                                >
                                    <Body1>${(accessorial.base || 0).toFixed(2)}</Body1>
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        width: 150px;
                                        padding-right: 50px;
                                    `}
                                >
                                    <CurrencyTextField
                                        size="small"
                                        value={accessorial.rate ? accessorial.rate * 100 : 0}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{'%'}</InputAdornment>,
                                            startAdornment: null,
                                        }}
                                        onBlur={(e) => {
                                            const next = (e.target.value || 0) / 100.0;
                                            const clone = [...enriched.accessorials];
                                            clone[idx] = {
                                                ...clone[idx],
                                                rate: next,
                                            };
                                            setOverrides(clone);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    css={css`
                                        width: 150px;
                                    `}
                                >
                                    <Body1>${((accessorial.base || 0) * (accessorial.rate || 0)).toFixed(2)}</Body1>
                                </Grid>
                            </Grid>
                        ))}
                    </GridCard>
                )}
            </Grid>
            <Grid
                container
                css={css`
                    padding: 1rem 2rem 5rem 2rem;
                `}
            >
                <GridCard>
                    <Grid xs={9} />
                    <Grid xs={3} container direction="column">
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Target Rate</Body1>
                            <Body2>{enriched.target_rate ? `$${enriched.target_rate.toFixed(2)}` : '-'}</Body2>
                        </Grid>
                        <hr />
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Calculated Rate</Body1>
                            <Body2>{enriched.calculated_rate ? `$${enriched.calculated_rate.toFixed(2)}` : '-'}</Body2>
                        </Grid>
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Adjustments</Body1>
                            <Body2
                                css={css`
                                    color: ${enriched.adjustments_rate >= 0
                                        ? colors.greens.primary
                                        : colors.reds.primary};
                                `}
                            >
                                {enriched.adjustments_rate ? `$${enriched.adjustments_rate.toFixed(2)}` : '-'}
                            </Body2>
                        </Grid>
                        <hr />
                        <Grid
                            container
                            css={css`
                                justify-content: space-between;
                            `}
                        >
                            <Body1>Total</Body1>
                            <Total>{enriched.final_rate ? `$${enriched.final_rate.toFixed(2)}` : '-'}</Total>
                        </Grid>
                    </Grid>
                </GridCard>
            </Grid>
        </Grid>
    );
};

export default DriverPayment;
