import { Card, Grid, IconButton } from '@material-ui/core';
import React, { useMemo, useContext, useState } from 'react';
import { css } from '@emotion/react';
import { Body1, Body2, CardHeader } from '../blocks';
import { colors } from '@/styles';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { uniq } from 'lodash';
import { PALLET_STATUSES, STAGED_STATUSES, STORED_STATUSES } from '@/constants/manifestStatuses';
import ItemSection from './ItemSection';
import ConsolidateSection from './ConsolidateSection';
import { Context } from './../store';

const OrderCard = ({ manifest, order, loading, callbacks }) => {
    const [open, setOpen] = useState(false);
    const { state } = useContext(Context);
    const hasExceptions = useMemo(() => {
        return Object.fromEntries((order.manifestItems || []).map((item) => [item.item_id, item.item_exception]));
    }, [order]);

    const [isConsolidated, locations] = useMemo(() => {
        return [
            (order.manifestItems || []).some((item) => item?.pallet?.type === 'CONSOLIDATED'),
            uniq((order.manifestItems || []).map((item) => item?.pallet?.warehouse_location)).filter((x) => x),
        ];
    }, [order]);

    const isStored = useMemo(() => {
        return (
            manifest.type === 'INBOUND' &&
            (order.manifestItems || []).every((item) => STORED_STATUSES.includes(item?.pallet?.warehouse_status))
        );
    }, [order]);

    const isStaged = useMemo(() => {
        return (
            ['OUTBOUND', 'RETURN_TO_SENDER', 'WILL_CALL'].includes(manifest.type) &&
            (order.manifestItems || []).every((item) =>
                [PALLET_STATUSES.PICKED_UP, PALLET_STATUSES.STAGED].includes(item?.pallet?.warehouse_status)
            )
        );
    }, [order]);

    return (
        <Card
            css={css`
                margin-top: 1rem;
            `}
        >
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    container
                    direction="row"
                    css={css`
                        padding: 1rem;
                        align-items: center;
                        justify-content: flex-end;
                        cursor: pointer;
                    `}
                    onClick={() => setOpen((prev) => !prev)}
                >
                    <Grid
                        item
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <CardHeader>
                            {order.order_number} / {order.po_number}
                        </CardHeader>
                    </Grid>
                    <Grid item>
                        {locations.length > 0 ? <Body2>{locations.join(', ')}</Body2> : null}
                        {isConsolidated ? (
                            <Body1
                                css={css`
                                    margin-left: 0.5rem;
                                    font-style: italic;
                                    color: ${colors.greys[1]};
                                `}
                            >
                                (Consolidated)
                            </Body1>
                        ) : null}
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 2rem;
                        `}
                    >
                        {Object.values(hasExceptions).some((exception) => !!exception) ? (
                            <WarningIcon
                                css={css`
                                    color: ${colors.oranges.primary};
                                `}
                            />
                        ) : null}
                    </Grid>
                    <Grid
                        item
                        css={css`
                            margin-left: 1rem;
                            width: 100px;
                        `}
                    >
                        {isStored || isStaged ? (
                            <>
                                <CheckCircleIcon
                                    css={css`
                                        color: ${colors.greens.primary};
                                    `}
                                />
                                <Body2
                                    css={css`
                                        margin-left: 0.25rem;
                                        color: ${colors.greens.primary};
                                    `}
                                >
                                    {isStored ? 'Stored' : isStaged ? 'Staged' : ''}
                                </Body2>
                            </>
                        ) : null}
                    </Grid>
                    <Grid item>
                        <IconButton
                            css={css`
                                padding: 0;
                            `}
                        >
                            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowLeftIcon />}
                        </IconButton>
                    </Grid>
                </Grid>
                {open ? (
                    <>
                        {(order.manifestItems || []).map((item) => (
                            <ItemSection
                                key={item.item_id}
                                manifest={manifest}
                                item={item}
                                order={order}
                                loading={loading}
                                callbacks={callbacks}
                                editable={state?.editable}
                            />
                        ))}
                        {state.editable && (
                            <ConsolidateSection
                                manifest={manifest}
                                items={order.manifestItems || []}
                                loading={loading}
                                callbacks={callbacks}
                            />
                        )}
                    </>
                ) : null}
            </Grid>
        </Card>
    );
};

export default OrderCard;
