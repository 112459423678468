import { useClientUser } from '@/hooks';
import { Grid, InputAdornment } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { Body1, Card, H1 } from '../blocks';
import { genAccessorials } from '../utils';
import { css } from '@emotion/react';
import { OnwardTab, OnwardTabContainer } from '@/components/Tabs';
import { ALGO_TYPES } from '../constants';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';

const Accessorial = ({ accessorial, rates, errors, callbacks }) => {
    return (
        <Grid
            container
            css={css`
                margin-bottom: 12px;
                justify-content: space-between;
            `}
        >
            <Grid item>
                <Body1>{accessorial.label}</Body1>
            </Grid>
            <Grid item>
                <CurrencyTextField
                    variant="outlined"
                    size="small"
                    value={(rates?.[accessorial.type]?.[0]?.rate || 0) * 100}
                    error={errors[accessorial.type] && !(rates?.[accessorial.type]?.[0]?.rate >= 0)}
                    onChange={(_, val) => {
                        callbacks.setRate({
                            bucket: 0,
                            type: accessorial.type,
                            rate: val / 100.0,
                        });
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">{'%'}</InputAdornment>,
                    }}
                />
            </Grid>
        </Grid>
    );
};

export const DriverAccessorials = ({ types, rates, errors, callbacks, className }) => {
    const { tags } = useClientUser();
    const [type, setType] = useState('');

    const accessorialsByAlgoType = useMemo(() => {
        return (types || []).reduce((acc, type) => {
            const accessorials = genAccessorials(type, tags);
            return {
                ...acc,
                [type]: accessorials,
            };
        }, {});
    }, [types, tags]);

    useEffect(() => {
        setType(Object.keys(accessorialsByAlgoType)[0]);
    }, [accessorialsByAlgoType]);

    return (
        <>
            <Card className={className}>
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                        flex: 0;
                        flex-basis: 0;
                    `}
                >
                    <H1>Freight Charge</H1>
                </Grid>
                <Accessorial
                    accessorial={{ type: 'FREIGHT_CHARGE', label: 'Freight Charge' }}
                    rates={rates}
                    errors={errors}
                    callbacks={callbacks}
                />
            </Card>
            <Card className={className}>
                <Grid
                    container
                    css={css`
                        margin-bottom: 12px;
                        flex: 0;
                        flex-basis: 0;
                    `}
                >
                    <H1>Accessorials</H1>
                </Grid>
                <OnwardTabContainer
                    value={type}
                    onChange={(e, t) => setType(t)}
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    {Object.keys(accessorialsByAlgoType).map((type) => (
                        <OnwardTab key={type} value={type} label={ALGO_TYPES[type] || type} />
                    ))}
                </OnwardTabContainer>

                {(accessorialsByAlgoType[type] || []).map((props) => (
                    <Accessorial
                        key={props.type}
                        accessorial={props}
                        rates={rates}
                        errors={errors}
                        callbacks={callbacks}
                    />
                ))}
            </Card>
        </>
    );
};
