import { CheckOutlined, HighlightOffOutlined } from '@material-ui/icons';
import { format } from 'date-fns';
import { startCase } from 'lodash';
import { css } from '@emotion/react';

export const TABS = {
    ALL: {
        label: 'All',
    },
    UNPAID: {
        label: 'Unpaid',
        statuses: ['UNPAID'],
    },
    PAID: {
        label: 'Paid',
        statuses: ['PAID'],
    },
};

export const COLUMNS = [
    {
        Header: 'Group',
        id: 'group',
        columns: [
            {
                Header: 'Driver',
                id: 'driver_id',
                width: 300,
                accessor: (payment) => payment?.driver?.username || '-',
            },
            {
                Header: 'Date',
                id: 'pay_period_start',
                width: 200,
                accessor: (payment) => payment?.pay_period_start || '',
                Cell: ({ value }) => {
                    return value ? format(new Date(value), 'EEE, MMM d, yyyy') : '-';
                },
            },
            {
                Header: 'Tariff',
                id: 'tariff',
                width: 150,
                accessor: (payment) => payment?.tariff?.name || '-',
            },
            {
                Header: 'Status',
                id: 'status',
                width: 150,
                accessor: (payment) => payment.status || '',
                Cell: ({ value }) => {
                    let icon;
                    switch (value) {
                        case 'PAID':
                            icon = <CheckOutlined color="primary" />;
                            break;
                        case 'UNPAID':
                            icon = <HighlightOffOutlined color="error" />;
                    }

                    return (
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            {icon}
                            {startCase(value.toLowerCase())}
                        </div>
                    );
                },
            },
            {
                Header: 'Target Rate',
                id: 'target_rate',
                width: 150,
                accessor: (payment) => payment.target_rate,
                Cell: ({ value }) => {
                    return value ? `$${value.toFixed(2)}` : '-';
                },
            },
            {
                Header: 'Calc Rate',
                id: 'calculated_rate',
                width: 150,
                accessor: (payment) => payment.calculated_rate,
                Cell: ({ value }) => {
                    return value ? `$${value.toFixed(2)}` : '-';
                },
            },
            {
                Header: 'Adjustments',
                id: 'adjustments_rate',
                width: 150,
                accessor: (payment) => payment.adjustments_rate,
                Cell: ({ value }) => {
                    return value ? `${value > 0 ? '+' : '-'} $${Math.abs(value.toFixed(2))}` : '-';
                },
            },
            {
                Header: 'Final Rate',
                id: 'final_rate',
                width: 150,
                accessor: (payment) => payment.final_rate,
                Cell: ({ value }) => {
                    return value ? `$${value.toFixed(2)}` : '-';
                },
            },
        ],
    },
];
