export const calcOrderPricing = (order, user_id, circles) => {
    let owner = calcOrderOwner(order, user_id);
    if (owner === 'Internal') {
        return '$' + order?.order_revenue?.toFixed(2) || 0;
    } else if (owner === 'Carrier') {
        return calcOrderCarrierPricing([order]);
    } else if (owner === 'Shipper') {
        return calcOrderShipperPricing([order], circles);
    }
    return 0;
};

export const calcOrderOwner = (order, user_id) => {
    if (order.oms) {
        return 'Internal';
    } else if (!order.oms && order.shipper_id !== order.carrier_id && order.carrier_id === user_id) {
        return 'Carrier';
    } else if (!order.oms && order.shipper_id !== order.carrier_id && order.shipper_id === user_id) {
        return 'Shipper';
    }
    return '';
};

export const calcOrderCarrierPricing = (orders, backupText = 'We will contact you') => {
    if (!orders) return;

    // If an order's shipper has the 'hide-market-rates' flag enable, then we should also hide the market rates for the carrier.
    // This is because most (90%+) of the shippers that have market rate contracts, also have the contract with a carrier for market rates.
    // See this discussion in bug reports: https://onwardteamworkspace.slack.com/archives/C027LRZCYGJ/p1690225765197839

    let totalRate;

    if (Array.isArray(orders)) {
        if (orders.every((o) => o.admin_carrier_rate_override || o.admin_carrier_rate_override === 0)) {
            totalRate = orders.reduce((total, o) => total + o.admin_carrier_rate_override, 0);
        } else if (orders.some((o) => o?.order_shipper?.user?.circles?.['hide-market-rates'])) {
            return 'Contract Rates';
        } else {
            totalRate = orders.reduce((total, o) => {
                const override = o.admin_carrier_rate_override || o.admin_carrier_rate_override === 0;
                const odRate = override
                    ? o.admin_carrier_rate_override
                    : o.listing?.final_accepted_carrier_rate ||
                      o.listing?.bids?.find((bid) => bid.carrier_id === o.carrier_id && bid.bid_status === 'succeeded')
                          ?.carrier_rate ||
                      o.carrier_rate ||
                      0;
                return total + odRate;
            }, 0);
        }

        return totalRate ? `$${totalRate.toFixed(2)}` : backupText;
    } else {
        if (orders?.admin_carrier_rate_override) {
            return `$${orders.admin_carrier_rate_override.toFixed(2)}`;
        }

        if (orders?.order_shipper?.user?.circles?.['hide-market-rates']) {
            return 'Contract Rates';
        }

        if (
            orders?.listing?.bids.find((bid) => bid.carrier_id === orders.carrier_id && bid.bid_status === 'succeeded')
        ) {
            return `$${
                orders.listing.final_accepted_carrier_rate?.toFixed(2) ||
                orders.listing.bids
                    .find((bid) => bid.carrier_id === orders.carrier_id && bid.bid_status === 'succeeded')
                    ?.carrier_rate?.toFixed(2)
            }`;
        }

        return orders?.carrier_rate ? `$${orders?.carrier_rate?.toFixed(2)}` : backupText;
    }
};

export const calcOrderShipperPricing = (orders, circles, payment_type, backupText = 'We will contact you') => {
    if (Array.isArray(orders)) {
        if (!orders?.length) return;

        if (circles?.['hide-market-rates']) {
            // Only return summed rate if every order has a value, otherwise return 'Contract Rates'
            if (orders.every((o) => !!o.admin_shipper_rate_override)) {
                let totalRate = orders.reduce((total, o) => {
                    return total + o.admin_shipper_rate_override;
                }, 0);
                return `$${totalRate?.toFixed(2)}`;
            } else {
                return 'Contract Rates';
            }
        } else {
            // Only return summed rate if every order has a value, otherwise return backup text
            let totalRate = 0;
            for (const order of orders) {
                if (order.admin_shipper_rate_override) {
                    totalRate += order.admin_shipper_rate_override;
                    continue;
                }

                const wonBid = order.listing?.bids?.find((bid) => bid.bid_status === 'succeeded');
                const orderRate = order.shipper_rate;

                if (order.listing?.final_accepted_shipper_rate) {
                    totalRate += order.listing.final_accepted_shipper_rate;
                } else if (wonBid) {
                    totalRate += wonBid.shipper_rate;
                } else if (orderRate) {
                    totalRate += orderRate;
                } else {
                    totalRate = null;
                    break;
                }
            }
            return totalRate ? `$${totalRate?.toFixed(2)}` : backupText;
        }
    } else {
        if (!orders) return;

        if (orders?.admin_shipper_rate_override) {
            return `$${orders?.admin_shipper_rate_override?.toFixed(2)}`;
        }

        if (circles?.['hide-market-rates']) {
            return 'Contract Rates';
        }

        if (orders?.listing?.final_accepted_shipper_rate) {
            return `$${orders?.listing?.final_accepted_shipper_rate.toFixed(2)}`;
        }

        if (orders?.listing?.bids?.find((bid) => bid.bid_status === 'succeeded')) {
            return `$${orders.listing.bids.find((bid) => bid.bid_status === 'succeeded').shipper_rate.toFixed(2)}`;
        }

        return orders?.shipper_rate ? `$${orders?.shipper_rate?.toFixed(2)}` : backupText;
    }
};
