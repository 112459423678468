export const RATES = [
    {
        name: 'Sunday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['SUNDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Monday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['MONDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Tuesday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['TUESDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Wednesday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['WEDNESDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Thursday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['THURSDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Friday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['FRIDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Saturday',
        xLabels: [''],
        yLabels: ['1 - 25 miles', '26 - 50 miles', '51 - 75 miles', '76 - 100 miles', '100 + miles'],
        buckets: [
            ...[0, 1, 2, 3, 4].map((idx) => {
                return ['SATURDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
];

export const DEFAULT_RATES = {
    SUNDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    MONDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    TUESDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    WEDNESDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    THURSDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    FRIDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
    SATURDAY_RATE: [
        { min: 0, max: 25 },
        { min: 25, max: 50 },
        { min: 50, max: 75 },
        { min: 75, max: 100 },
        { min: 100, max: 'infinity' },
    ],
};
