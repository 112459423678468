import { asDateInTZ } from '@/utilities/convertToISO';
import { useApolloClient, useMutation } from '@apollo/client';
import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { UPSERT_ORDERS } from '../ShipmentForm/graphql/mutations';
import { genAttributes } from '@onward-delivery/core';
import { INSERT_EVENTS, UPDATE_ORDERS } from './graphql/mutations';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import useAction from '@/utilities/useQuery';
import { useClientUser } from '@/hooks';
import { createListings } from '../ShipmentForm/queries/createListings';

export const useCallbacks = (state, callbacks) => {
    const { user_id } = useClientUser();
    const { cache } = useApolloClient();

    const [upsertOrder] = useMutation(UPSERT_ORDERS, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
        onError: (e) => {
            console.error(e);
            callbacks.setNotification({ severity: 'error', message: 'Failed to save order. Please try again.' });
            captureException(e);
        },
    });

    const [updateOrders] = useMutation(UPDATE_ORDERS, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
    });

    const [insertEvents] = useMutation(INSERT_EVENTS, {
        update: (cache) => {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
        },
    });

    const [submitListings] = useAction(createListings, {
        onComplete: ({ data }) => {
            if (data?.success?.length > 0) {
                callbacks.setNotification({ severity: 'success', message: 'Successfully sent order to marketplace!' });
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'orders' });
                cache.evict({ id: 'ROOT_QUERY', fieldName: 'listings' });
            } else {
                callbacks.setNotification({
                    severity: 'error',
                    message: 'Error sending order to marketplace. Please try again.',
                });
            }
        },
        onError: (err) => {
            console.error(err);
            captureException(err);
        },
    });

    const onSaveOrder = useCallback(
        ({ errors, order: modified, items: itemsModified, itemsRemoved, tags, tagsRemoved }) => {
            if (errors?.hasMissingField?.length) {
                callbacks.setNotification({
                    severity: 'error',
                    message: `Order has missing fields: ${errors.hasMissingField.join(', ')}.`,
                });
            } else if (errors?.phoneInvalid) {
                callbacks.setNotification({ severity: 'error', message: 'Order has an invalid phone number.' });
            } else {
                if (errors?.failedPricing?.length) {
                    let message = `Failed to calculate pricing for ${modified.order_number}.`;
                    if (errors.failedPricing.includes('miles')) {
                        message = `${message} Cannot find route between pickup and dropoff locations.`;
                    }
                    if (errors.failedPricing.includes('delivery_type')) {
                        message = `${message} ${
                            modified.order_type === 'return' ? 'Return Pickup' : 'Delivery'
                        } type not set.`;
                    }
                    callbacks.setNotification({ severity: 'warning', message });
                }

                const {
                    warehouse_delivery_notes,
                    warehouse_estimated_delivery_date,
                    warehouse_estimated_ship_date,
                    ...rest
                } = modified;

                upsertOrder({
                    variables: {
                        orders: [
                            {
                                ...rest,
                                ...(warehouse_delivery_notes ||
                                warehouse_estimated_delivery_date ||
                                warehouse_estimated_ship_date
                                    ? {
                                          wh_events: {
                                              data: [
                                                  {
                                                      action: 'START:RECEIVING',
                                                      notes: warehouse_delivery_notes,
                                                      est_received_date: warehouse_estimated_delivery_date,
                                                      est_ship_date: warehouse_estimated_ship_date,
                                                  },
                                              ],
                                          },
                                      }
                                    : {}),
                            },
                        ],
                        items: itemsModified,
                        removals: itemsRemoved,
                        tags: tags,
                        tag_removals: tagsRemoved,
                    },
                });
                callbacks.editOrder(null);
            }
        },
        []
    );

    const sendToPlanning = useCallback(
        (deliveryDate, sendMarketplaceOrders) => {
            const selectedClone = [...state.selectedOrders];
            let ordersToSend = selectedClone;
            if (!sendMarketplaceOrders) {
                ordersToSend = selectedClone.filter((o) => {
                    const isInternal = o?.oms;
                    if (isInternal) return true;
                    return false;
                });
            }

            const invalid = ordersToSend.filter((order) =>
                ['dropoff_lat', 'dropoff_lng', 'pickup_lat', 'pickup_lng'].some((field) => !order[field])
            );
            if (invalid.length > 0) {
                callbacks.setNotification({
                    severity: 'error',
                    message: `The following order(s) [${invalid
                        .map((order) => order.order_number)
                        .join(', ')}] could not be sent to planning due to missing location information.`,
                });
                return;
            }

            const first = ordersToSend[0];
            const { zip } = genAttributes(first);
            const tz = zipcode_to_timezone.lookup(first[zip]) || 'America/New_York';
            const delivery_date = asDateInTZ(deliveryDate, tz).toISOString();

            const pickups = ordersToSend.filter((o) => o.crossdock_leg === 'pickup').map((o) => o.order_id);
            const dropoffs = ordersToSend.filter((o) => o.crossdock_leg !== 'pickup').map((o) => o.order_id);

            updateOrders({
                variables: {
                    updates: [
                        {
                            where: { order_id: { _in: pickups } },
                            _set: {
                                pickup_date: delivery_date,
                                pickup_planning: true,
                            },
                        },
                        {
                            where: { order_id: { _in: dropoffs } },
                            _set: {
                                delivery_date,
                                planning: true,
                            },
                        },
                    ],
                },
                onError: (e) => {
                    callbacks.setNotification({
                        severity: 'error',
                        message: 'Error sending orders to planning. Please try again.',
                    });
                    captureException(e);
                },
                onCompleted: () => {
                    callbacks.setNotification({
                        severity: 'success',
                        message: `Successfully sent ${ordersToSend.length} orders to planning!`,
                    });
                    callbacks.setModal(null);
                },
            });
        },
        [state.selectedOrders]
    );

    const crossdock = useCallback((orders, warehouseId, showRevenueOn) => {
        const events = orders.map((order) => ({
            order_id: order.order_id,
            action: `${order.event_state}:ADD_CD`,
            location_id: warehouseId,
        }));

        const orderUpdates = orders.map((order) => ({
            where: { order_id: { _eq: order.order_id } },
            _set: {
                show_revenue_on: showRevenueOn,
            },
        }));

        insertEvents({
            variables: {
                events,
                orderUpdates,
            },
            onError: (e) => {
                callbacks.setNotification({
                    severity: 'error',
                    message: 'Error crossdocking orders. Please try again.',
                });
                captureException(e);
            },
            onCompleted: () => {
                callbacks.setNotification({
                    severity: 'success',
                    message: `Successfully cross-docked ${events.length} orders!`,
                });
                callbacks.setModal(null);
            },
        });
    }, []);

    const undoSendToMarketplace = useCallback((undoOrders) => {
        updateOrders({
            variables: {
                order_ids: undoOrders.map((order) => order.order_id),
                update: {
                    oms: true,
                    order_status: 'pending',
                },
            },
            onError: (e) => {
                callbacks.setNotification({
                    severity: 'error',
                    message: 'Error removing orders from marketplace.',
                });
                captureException(e);
            },
            onCompleted: () => {
                callbacks.setNotification({
                    severity: 'success',
                    message: `Successfully removed ${undoOrders.length} orders from the marketplace.`,
                });
            },
        });
    }, []);

    const sendToMarketplace = useCallback(() => {
        const invalid = state.selectedOrders.filter((order) =>
            ['dropoff_lat', 'dropoff_lng', 'pickup_lat', 'pickup_lng'].some((field) => !order[field])
        );
        if (invalid.length > 0) {
            callbacks.setNotification({
                severity: 'error',
                message: `The following order(s) [${invalid
                    .map((order) => order.order_number)
                    .join(', ')}] could not be sent to the marketplace due to missing location information.`,
            });
            return;
        }

        submitListings({
            shipper_id: user_id,
            listings: Object.values(state.listings).map((listing) => ({ ...listing })),
        });

        updateOrders({
            variables: {
                order_ids: state.selectedOrders.map((order) => order.order_id),
                update: {
                    carrier_id: null,
                    claimed_date: null,
                    delivery_date: null,
                    pickup_date: null,
                    order_status: 'pending',
                    oms: false,
                },
            },
            onError: (e) => {
                callbacks.setNotification({
                    severity: 'error',
                    message: 'Error sending orders to marketplace. Please try again.',
                });
                captureException(e);
            },
            onCompleted: () => {
                callbacks.setNotification({
                    severity: 'success',
                    message: `Successfully sent ${state.selectedOrders.length} orders to the marketplace!`,
                    action: (
                        <span
                            onClick={() => undoSendToMarketplace(state.selectedOrders)}
                            css={css`
                                cursor: pointer;
                                text-decoration: underline;
                            `}
                        >
                            Undo
                        </span>
                    ),
                });
                callbacks.setModal(null);
            },
        });
    }, [state.selectedOrders, state.listings]);

    return {
        onSaveOrder,
        sendToPlanning,
        sendToMarketplace,
        crossdock,
    };
};
