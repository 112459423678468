import { asDateInTZ } from '@/utilities/convertToISO';
import { formatInTimeZone } from 'date-fns-tz';
import zipcode_to_timezone from 'zipcode-to-timezone';

export const INVENTORY_TABS = [
    {
        label: 'All',
        value: 0,
        orderQuery: {
            order_status: {
                _in: [
                    'pending',
                    'active',
                    'routed',
                    'claimed',
                    'inProgress',
                    'routedInProgress',
                    'complete',
                    'routedComplete',
                ],
            },
        },
    },
    {
        label: 'Not Received',
        value: 1,
        itemQuery: { itemsByOrderId: { item_sku_status: { _eq: `NOT_RECEIVED` } } },
        orderQuery: {
            _or: [
                {
                    _and: [
                        { order_type: { _eq: 'delivery' } },
                        {
                            order_status: {
                                _in: ['pending', 'active', 'routed', 'claimed', 'inProgress', 'routedInProgress'],
                            },
                        },
                    ],
                },
                {
                    _and: [
                        { order_type: { _neq: 'delivery' } },
                        {
                            order_status: {
                                _in: [
                                    'pending',
                                    'active',
                                    'routed',
                                    'claimed',
                                    'inProgress',
                                    'routedInProgress',
                                    'complete',
                                    'routedComplete',
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
    {
        label: 'In Stock',
        value: 2,
        itemQuery: { itemsByOrderId: { item_sku_status: { _eq: `IN_STOCK` } } },
        orderQuery: {
            _or: [
                {
                    _and: [
                        { order_type: { _eq: 'delivery' } },
                        {
                            order_status: {
                                _in: ['pending', 'active', 'routed', 'claimed', 'inProgress', 'routedInProgress'],
                            },
                        },
                    ],
                },
                {
                    _and: [
                        { order_type: { _neq: 'delivery' } },
                        {
                            order_status: {
                                _in: [
                                    'pending',
                                    'active',
                                    'routed',
                                    'claimed',
                                    'inProgress',
                                    'routedInProgress',
                                    'complete',
                                    'routedComplete',
                                ],
                            },
                        },
                    ],
                },
            ],
        },
    },
    {
        label: 'Complete',
        value: 3,
        itemQuery: {
            itemsByOrderId: { item_sku_status: { _eq: `COMPLETE` } },
        },
        orderQuery: {
            order_status: {
                _in: [
                    'pending',
                    'active',
                    'routed',
                    'claimed',
                    'inProgress',
                    'routedInProgress',
                    'complete',
                    'routedComplete',
                ],
            },
        },
    },
];

export const INVENTORY_CSV_COLUMNS = [
    { header: 'SKU', value: (palletItem) => palletItem.sku || '' },
    { header: 'Pallet Id', value: (palletItem) => palletItem.palletId || '' },
    { header: 'Order Number', value: (palletItem) => palletItem.order_number || '' },
    { header: 'PO Number', value: (palletItem) => palletItem.po_number || '' },
    { header: 'Shipper', value: (palletItem) => palletItem.shipper || '' },
    { header: 'Qty In', value: (palletItem) => `${palletItem.qtyIn || 0}` },
    { header: 'Qty Out', value: (palletItem) => `${palletItem.qtyOut || 0}` },
    { header: 'Qty On Hand', value: (palletItem) => `${palletItem.qtyOnHand || 0}` },
    { header: 'Qty Total', value: (palletItem) => `${palletItem.qtyTotal || 0}` },
    { header: 'Bins/Locations', value: (palletItem) => palletItem.locations || '-' },
    { header: 'Date In', value: (palletItem) => palletItem.dateIn || '' },
    { header: 'Date Out', value: (palletItem) => palletItem.dateOut || '' },
    { header: 'Inbound Manifests', value: (palletItem) => palletItem.inboundManifests || '-' },
    { header: 'Outbound Manifests', value: (palletItem) => palletItem.outboundManifests || '-' },
    { header: 'Status', value: (palletItem) => palletItem.status || 'Not Received' },
];
