import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Divider } from '@material-ui/core';

export const NavDivider = styled((props) => <Divider {...props} />)`
    background-color: white !important;
`;

export const white = css`
    color: white;
`;

export const iconcss = css`
    margin: 13px 13px;
`;

export const lockiconcss = css`
    margin-left: auto;
    margin-right: 13px;
    display: flex;
    align-items: center;
`;

export const LinkContainer = styled((props) => <div {...props} />)`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: ${(props) =>
        props.locked ? '#525252' : props.isOnwardGreenLink ? 'white' : props.selected ? '#eee' : '#ffffff80'};
    font-weight: ${(props) => (props.isOnwardGreenLink && props.selected ? 'bold' : 'normal')};
    cursor: pointer;
    background-color: ${(props) =>
        props.selected && !props.isOnwardGreenLink ? 'rgba(217, 217, 217, 0.2)' : 'transparent'};
    &:hover {
        background-color: rgba(217, 217, 217, 0.2);
    }
`;

export const LinkLabel = styled.span`
    font-size: 15px;
    font-weight: 600;
`;

export const SublinkIcon = styled.div`
    margin: 13px 13px;
    height: 12px;
    width: 24px;
`;
