import React, { useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Typography, Button } from '@material-ui/core';
import { ACCEPT_BID } from '@/constants/apiRoutes';
import { post } from '@/utilities/onwardClient';
import { useClientUser } from '@/hooks';
import { BiddingPrice } from '@/components/ShipmentForm/blocks';

export default function AwardBidModal({ bid, onClose, open, listing, selectedCounter, isOnwardHandledQuoteShipper }) {
    const [errorMessage, setErrorMessage] = useState('');
    const [acceptLoading, setAcceptLoading] = useState(false);
    const { isOnwardAdmin } = useClientUser();
    const [onwardQuoteShipperOverride, setOnwardQuoteShipperOverride] = useState(
        bid?.counterSequence[0]?.shipper_rate ? bid?.counterSequence[0]?.shipper_rate : bid.shipper_rate
    );
    const [onwardQuoteCarrierOverride, setOnwardQuoteCarrierOverride] = useState(
        bid?.counterSequence[0]?.carrier_rate ? bid?.counterSequence[0]?.carrier_rate : bid.carrier_rate
    );

    const handleSubmit = async () => {
        setAcceptLoading(true);
        try {
            await post(ACCEPT_BID, {
                listing_id: listing.listing_id,
                bid_id: bid.bid_id,
                counter_offer_id: selectedCounter ? selectedCounter.counter_offer_id : null,
                ...(isOnwardHandledQuoteShipper &&
                    isOnwardAdmin && {
                        onward_quote_shipper_override: onwardQuoteShipperOverride,
                        onward_quote_carrier_override: onwardQuoteCarrierOverride,
                    }),
            });
            onClose();
        } catch (e) {
            setErrorMessage('There was a problem selecting the bid.');
            setAcceptLoading(false);
        }
        setAcceptLoading(false);
    };

    const price = useMemo(() => {
        if (selectedCounter) {
            if (bid.isCarrier || isOnwardHandledQuoteShipper) {
                return selectedCounter.carrier_rate.toFixed(2);
            } else return selectedCounter.shipper_rate.toFixed(2);
        } else {
            if (bid.isCarrier || isOnwardHandledQuoteShipper) {
                return bid.carrier_rate.toFixed(2);
            } else return bid.shipper_rate.toFixed(2);
        }
    }, [selectedCounter, bid]);

    return (
        <>
            <Dialog
                className="carrier-review-modal"
                open={open}
                onClose={onClose}
                maxWidth="xl"
                onClick={(e) => e.stopPropagation()}
            >
                {isOnwardHandledQuoteShipper && isOnwardAdmin ? (
                    <DialogContent>
                        <Typography className="mb-2">{`Are you sure you want to award the order to this carrier?
                        `}</Typography>
                        <Typography className="mb-2">You cannot undo this.</Typography>
                        <Typography className="mb-2">{`Carrier Rate: $${onwardQuoteCarrierOverride}`}</Typography>
                        <BiddingPrice
                            value={onwardQuoteShipperOverride}
                            onChange={(val) => setOnwardQuoteShipperOverride(val)}
                            sliderMin={0}
                            sliderSuggested={onwardQuoteShipperOverride}
                            sliderMax={bid.shipper_rate * 2}
                            label="Set the final rate you plan to charge shipper"
                        />
                        {errorMessage && <Typography className={`text-danger`}>{errorMessage}</Typography>}
                    </DialogContent>
                ) : (
                    <DialogContent>
                        <Typography className="mb-2">{`Are you sure you want to accept this price ${
                            bid.isCarrier ? 'and claim this order?' : 'and award the order to this carrier?'
                        }`}</Typography>
                        <Typography className="mb-2">You cannot undo this.</Typography>
                        <Typography className="mb-2">{`Price: $${price}`}</Typography>
                        {errorMessage && <Typography className={`text-danger`}>{errorMessage}</Typography>}
                    </DialogContent>
                )}

                <DialogActions
                    className="customer-track-rating-modal d-flex justify-content-between"
                    style={{ padding: '24px' }}
                >
                    <Button variant="outlined" onClick={onClose} style={{ color: 'black' }}>
                        Cancel
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#59B863',
                            color: 'white',
                            marginLeft: '55px',
                            width: '100px',
                        }}
                        disabled={acceptLoading}
                        onClick={handleSubmit}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
