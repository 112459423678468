export const START_OPTIMIZATION = '/v2-lib-ftl-optimizeRoute';
export const OPTIMIZATION_RESULT = '/v2-lib-ftl-optimizeRoute/:runId';
export const SUBMIT_ROUTE = '/v2-lib-ftl-submitRoute';
export const RETRY_STOP = '/v2-lib-ftl-retryStop';
export const DELAY_STOP = '/v2-lib-ftl-delayStop';
export const CONFIRM_STOP = '/v2-lib-ftl-confirmStop';
export const CROSSDOCK_CONFIRM_TIMEFRAME_TEXT = 'v2-lib-ltl-crossdockConfirmTimeframeText';
export const VERIFY_TOKEN = '/v2-lib-verifyToken';
export const INVITE_TEMP_DRIVER = '/v2-auth/inviteTempDriver';
export const INVITE_TEAMMATE = '/v2-auth/inviteTeammate';
export const CREATE_TEAMMATE = '/v2-auth/createTeammate';
export const CREATE_CLIENT = '/v2-auth/createClient';
export const VALIDATE_EMAIL = '/v2-auth/validateEmail';
export const CREATE_CORPORATE_ADMIN = '/v2-auth/createCorporateAdmin';
export const RESET_ACCOUNT = '/v2-auth/resetAccount';
export const INVITE_PARTNER = '/v2-auth/invitePartner';
export const PRICING_LTL = '/v2-pricing/ltl-order-pricing';
export const GET_INSTANT_QUOTE = 'v2-pricing/instant-quote';
export const STRIPE_CREATE_CUSTOMER = '/v2-lib-stripe/createCustomer';
export const STRIPE_CREATE_PAYMENT = '/v2-lib-stripe/createPayment';
export const STRIPE_CREATE_SETUP_INTENT = '/v2-lib-stripe/createSetupIntent';
export const STRIPE_GET_CARD_DETAILS = '/v2-lib-stripe/getCardDetails';
export const PAYMENTS_CAPTURE = '/v2-payments/capture';
export const PAYMENTS_CANCEL = '/v2-payments/cancel';
export const REMOVE_CARD = '/v2-lib-stripe/removeCard';
export const CUSTOMER_SHIPMENT = '/v2-lib-customerShipment';
export const CUSTOMER_SHIPMENT_TRACKING = '/v2-lib-customerShipment/tracking';
export const CUSTOMER_REQUEST_PAYMENT = '/v2-lib-customerRequestPayment';
export const CREATE_ORDER_JOB = '/v2-lib-jobs-init';
export const ORDER_RECOMMENDATIONS = '/v2-api-recommendations/orders';
export const NOTIFY_CARRIERS_OF_LTL_ORDER = `/v2-api-communications/notify-carriers-ltl-orders`;
export const PREDELIVERY_SMS = `/v2-api-communications/predelivery-sms`;
export const CUSTOM_SMS = '/v2-api-communications/custom-sms';
export const INVITE_PARTNER_CARRIER = `/v2-auth/invite-partner-carrier`;
export const CREATE_LISTINGS = '/v2-api-marketplace/listings';
export const PLACE_BID = '/v2-api-marketplace/listings/place-bid';
export const SUBMIT_COUNTER = 'v2-api-marketplace/listings/submit-counter';
export const CLAIM_NOW = '/v2-api-marketplace/listings/claim-now';
export const ACCEPT_BID = '/v2-api-marketplace/listings/accept-bid';
export const CANCEL_LISTING = '/v2-api-marketplace/listings/cancel';
export const MAPBOX_OPTIMIZE = '/v2-lib-mapbox/optimize';
export const SUBMIT_AVAILABILITY = '/v2-lib-submitAvailability';
export const PARSE_PDF = '/v2-lib-jobs-parsePdf';
export const PRICE_CUSTOMER_UPGRADES = '/v2-lib-customerShipment/upgrade';
export const FETCH_ROCKET_STATUS_UPDATES = '/v2-lib-fetchRocketOrderUpdates';
export const MANUAL_ROCKET_PUSH = '/v2-lib-pushMiddleMileIntegration';
export const MANUAL_DISPATCH_PARTNER_SUBMIT = '/v2-lib-sendOrdersToExternalDispatchClients';
export const EXCEPTIONS_EXPORT_CSV = '/v2-exceptions-export/csv';
export const ORDERS_EXPORT_CSV = '/v2-orders-export/csv';
export const MANUAL_DISPATCH_PARTNER_UPDATE = '/v2-lib-updateSingleDispatchIntegrationOrder';
export const RETRY_MESSAGES = '/v2-lib-messages/retry';
export const SCHEDULE_ORDER_CALL = '/v2-lib-ftl-base/voice/call';
