import React, { useMemo } from 'react';
import { LOCATION_TYPES } from '@/constants/locationTypes';
import deliveryStrings from '@/constants/deliveryStrings';
import { asBrowserDate } from '@/utilities/convertToISO';
import { format } from 'date-fns';
import { ITEM_TYPES, FREIGHT_TYPES, ASSEMBLY_TYPES } from '../ShipmentForm/constants/freightTypes';

const itemLine = (item, isHaulaway = false) => {
    const weight = parseInt(item.total_weight || item.weight * item.quantity || 0);
    const cubes = parseInt(
        item.total_cubes ||
            item.cubes_per_unit * item.quantity ||
            ((item.length * item.width * item.height) / 1728) * item.quantity ||
            0
    );
    return {
        details: `${
            item.item_type
                ? ITEM_TYPES[item.item_type]?.find((el) => el.value === item.item_type_details)?.label ||
                  FREIGHT_TYPES.find((el) => el.value === item.item_type)?.label ||
                  'Item'
                : 'Item'
        }: ${item.description ? `${item.description} ` : ''}${item.quantity} Count (${item.length}L ${item.width}W ${
            item.height
        }H / ${cubes} cu ft / ${weight}lbs / ${item.service_time ? item.service_time + 'min,' : ''} ${
            isHaulaway ? 'Disposal' : item.is_packaged ? 'Boxed' : 'Unboxed'
        })`,
        pic: item.pic,
    };
};

export const useLoadSummary = (orders) => {
    const [
        order_numbers,
        shipper,
        pickupAddress,
        dropoffAddress,
        pickupCity,
        pickupTypes,
        dropoffCity,
        deliveryTypes,
        details,
        price,
        availableTimestamp,
        custAvailableDates,
        recommendedEquipment,
        itemDetails,
        haulawayDetails,
        orderTypes,
        pickupComments,
        dropoffComments,
        pickupDetails,
        pickupStairs,
        dropoffStairs,
        specialInstructions,
        assemblyTypes,
        bidCount,
        lowestBid,
        isMiddleMileDelivery,
        palletizeReturns,
        bol_file,
        piece_count,
        assemblyDocs,
    ] = useMemo(() => {
        return [
            orders.map((order) => order.order_number).join(', '),
            Array.from(new Set(orders.map((order) => order.order_shipper?.business_name || 'N/A'))).join(', '),
            Array.from(new Set(orders.map((order) => order.pickup_address))).join(', '),
            Array.from(new Set(orders.map((order) => order.dropoff_address))).join(', '),
            Array.from(new Set(orders.map((order) => order.pickup_city))).join(', '),
            Array.from(
                new Set(
                    orders
                        .filter((order) => order.pickup_location_type)
                        .map((order) => LOCATION_TYPES[order.pickup_location_type] || order.pickup_location_type)
                )
            ).join(', '),
            Array.from(new Set(orders.map((order) => order.dropoff_city))).join(', '),
            Array.from(
                new Set(
                    orders
                        .filter((order) => order.dropoff_location_type)
                        .map((order) => LOCATION_TYPES[order.dropoff_location_type] || order.dropoff_location_type)
                )
            ).join(', '),
            Array.from(
                new Set(
                    orders.map(
                        (order) =>
                            `${order.dropoff_location}${
                                order.dropoff_location_info ? ` - ${order.dropoff_location_info}` : ''
                            }`
                    )
                )
            ).join(', '),
            orders.reduce((acc, order) => {
                const carr_rate = order.admin_carrier_rate_override || order.carrier_rate || 0;
                return acc + carr_rate;
            }, 0),
            orders.reduce((acc, order) => {
                if (!acc) {
                    return order.first_available_date;
                } else if (new Date(order.first_available_date) > new Date(acc)) {
                    return order.first_available_date;
                }
                return acc;
            }, null),
            Array.from(
                new Set(
                    orders.map((order) =>
                        order.preferred_delivery_date && order.alternative_delivery_dates
                            ? [
                                  order.preferred_delivery_date_formatted,
                                  ...order.alternative_delivery_dates_formatted,
                              ].join(' / ')
                            : 'N/A'
                    )
                )
            ).join(', '),
            Array.from(
                new Set(
                    orders.flatMap((order) => {
                        if (order.recommended_equipment) {
                            return Object.keys(order.recommended_equipment).filter(
                                (key) => order.recommended_equipment[key]
                            );
                        } else return [];
                    })
                )
            ),
            orders.flatMap((order) => {
                if (order.itemsByOrderId) {
                    return order.itemsByOrderId.map((item) => itemLine(item));
                } else return [];
            }),
            orders.flatMap((order) => {
                if (order.haulaway_items) {
                    return order.haulaway_items.map((item) => itemLine(item, true));
                } else return [];
            }),
            Array.from(new Set(orders.map((order) => deliveryStrings.deliveryTypes[order.order_type]))).join(', '),
            Array.from(new Set(orders.map((order) => order.pickup_comments))).join(', '),
            Array.from(new Set(orders.map((order) => order.dropoff_comments))).join(', '),
            Array.from(
                new Set(
                    orders.map(
                        (order) =>
                            `${order.pickup_location}${
                                order.pickup_location_info ? ` - ${order.pickup_location_info}` : ''
                            }`
                    )
                )
            ).join(', '),
            orders.reduce((stairQuantities, order) => {
                if (order.pickup_stairs) {
                    return [...stairQuantities, order.pickup_stair_quantity];
                }
                return stairQuantities;
            }, []),
            orders.reduce((stairQuantities, order) => {
                if (order.del_stairs) {
                    return [...stairQuantities, order.delivery_stair_quantity];
                }
                return stairQuantities;
            }, []),
            orders.reduce((acc, order) => {
                if (order.customer_instructions) {
                    return [...acc, order.customer_instructions];
                }
                return acc;
            }, []),
            (() => {
                let flatAssemblyTypes = orders.reduce((acc, order) => {
                    const itemAssemblyTypes = order.itemsByOrderId.reduce((acc, i) => {
                        if (i.assembly_type) {
                            return [...acc, i.assembly_type];
                        }
                        return acc;
                    }, []);
                    return [...acc, ...itemAssemblyTypes];
                }, []);

                let reducedAssemblyTypes = flatAssemblyTypes.reduce(
                    (acc, assemblyType) => {
                        if (assemblyType === 'no_assembly' && !acc.assemblyRequired) {
                            return {
                                assemblyRequired: false,
                                assemblyTypes: [
                                    ...acc.assemblyTypes,
                                    ASSEMBLY_TYPES.find((i) => i.value === assemblyType).display,
                                ],
                            };
                        } else if (assemblyType !== 'no_assembly') {
                            if (acc.assemblyRequired) {
                                return {
                                    assemblyRequired: true,
                                    assemblyTypes: [
                                        ...acc.assemblyTypes,
                                        ASSEMBLY_TYPES.find((i) => i.value === assemblyType).display,
                                    ],
                                };
                            } else {
                                return {
                                    assemblyRequired: true,
                                    assemblyTypes: [ASSEMBLY_TYPES.find((i) => i.value === assemblyType).display],
                                };
                            }
                        }
                        return acc;
                    },
                    {
                        assemblyRequired: false,
                        assemblyTypes: [],
                    }
                ).assemblyTypes;

                let assemblyTypesString = Array.from(new Set(reducedAssemblyTypes)).join(', ');
                return assemblyTypesString;
            })(),
            orders.reduce((bidCountAcc, order) => {
                const orderBids = order?.listing?.bids || [];
                return bidCountAcc + orderBids.length;
            }, 0),
            orders.reduce((lowestBidAcc, order) => {
                const lowestOrderBid = order?.listing?.bids?.reduce((lowestOrderBidAcc, bid) => {
                    if (!lowestOrderBidAcc || bid.carrier_rate < lowestOrderBidAcc) {
                        return bid;
                    }
                    return lowestOrderBidAcc;
                }, null);

                if (!lowestBidAcc || (lowestOrderBid && lowestOrderBid.carrier_rate < lowestBidAcc.carrier_rate)) {
                    return lowestOrderBid;
                }
                return lowestBidAcc;
            }, null),
            orders.some((o) => !!o?.is_middle_mile),
            orders.some((o) => !!o?.palletize_returns),
            Array.from(new Set(orders.map((order) => order.bol_file))).join(', '),
            orders.reduce((count, o) => {
                if (!Number.isInteger(count)) return null;

                const countFromItems = o?.itemsByOrderId?.reduce((itemCount, i) => {
                    if (!Number.isInteger(itemCount) || !i.item_piece_count) {
                        return null;
                    } else {
                        return itemCount + i.item_piece_count;
                    }
                }, 0);

                if (!countFromItems) {
                    return null;
                } else {
                    return count + countFromItems;
                }
            }, 0),
            orders.reduce((accum, order) => {
                const docs = (order?.documents || [])?.reduce((accum, doc_mapping) => {
                    if (doc_mapping.document.type !== 'Assembly') {
                        return accum;
                    }
                    accum.push(doc_mapping.document);
                    return accum;
                }, [])
                return [
                    ...accum,
                    ...docs,
                ]   
            }, [])
        ];
    }, [orders]);

    const available = availableTimestamp ? format(asBrowserDate(availableTimestamp), 'MM/dd/yyyy') : 'N/A';
    // Sometimes the flag for stairs existing for an order will be true, but the stair quantity will be null, or blank
    const parseStairs = (_stairs) => {
        if (!_stairs.length) return 'No';
        const filteredStairs = _stairs.filter((s) => !!s);
        if (!filteredStairs.length) return 'Yes';
        return filteredStairs.join(', ');
    };

    return {
        order_numbers,
        shipper,
        pickupAddress,
        dropoffAddress,
        pickupCity,
        pickupTypes,
        dropoffCity,
        deliveryTypes,
        details,
        price,
        available,
        custAvailableDates,
        recommendedEquipment,
        itemDetails,
        haulawayDetails,
        orderTypes,
        pickupComments,
        dropoffComments,
        pickupDetails,
        pickupStairs: parseStairs(pickupStairs),
        dropoffStairs: parseStairs(dropoffStairs),
        specialInstructions,
        assemblyTypes,
        bidCount,
        lowestBid,
        isMiddleMileDelivery,
        palletizeReturns,
        bol_file,
        piece_count,
        assemblyDocs,
    };
};
