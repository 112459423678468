export const RATES = [
    {
        name: 'Sunday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['SUNDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Monday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['MONDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Tuesday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['TUESDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Wednesday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['WEDNESDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Thursday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['THURSDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Friday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['FRIDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
    {
        name: 'Saturday',
        xLabels: [''],
        yLabels: ['Base Rate'],
        buckets: [
            ...[0].map((idx) => {
                return ['SATURDAY_RATE'].map((type) => {
                    return {
                        type,
                        bucket: idx,
                    };
                });
            }),
        ],
    },
];

export const DEFAULT_RATES = {
    SUNDAY_RATE: [{ min: 0, max: 'infinity' }],
    MONDAY_RATE: [{ min: 0, max: 'infinity' }],
    TUESDAY_RATE: [{ min: 0, max: 'infinity' }],
    WEDNESDAY_RATE: [{ min: 0, max: 'infinity' }],
    THURSDAY_RATE: [{ min: 0, max: 'infinity' }],
    FRIDAY_RATE: [{ min: 0, max: 'infinity' }],
    SATURDAY_RATE: [{ min: 0, max: 'infinity' }],
};
