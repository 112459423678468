import { DRIVER_PAYMENT_FIELDS, DRIVER_PAYMENT_MAPPINGS } from '@/graphql/fragments/driver_payments';
import { gql } from '@apollo/client';

export const UPDATE_DRIVER_PAYMENT = gql`
    ${DRIVER_PAYMENT_FIELDS}

    mutation UpdateDriverPayment($payment_id: uuid!, $update: driver_payments_set_input!) {
        update_driver_payments_by_pk(pk_columns: { payment_id: $payment_id }, _set: $update) {
            ...DriverPaymentFields
        }
    }
`;

export const GET_DRIVER_PAYMENT = gql`
    ${DRIVER_PAYMENT_FIELDS}
    ${DRIVER_PAYMENT_MAPPINGS}

    query GetDriverPayment($payment_id: uuid!) {
        driver_payment: driver_payments_by_pk(payment_id: $payment_id) {
            ...DriverPaymentFields
            driver {
                teammate_id
                username
            }
            mappings {
                ...DriverPaymentMappings
                order {
                    order_id
                    po_number
                    oms
                    carrier_rate
                    order_revenue
                    price_breakdown
                }
                route {
                    route_id
                    route_number
                    estimated_driving_distance
                    orders {
                        mapping_id
                        order {
                            order_id
                            po_number
                            oms
                            price_breakdown
                            miles
                        }
                    }
                }
            }
        }
    }
`;
