import { gql } from '@apollo/client';

export const NOTE_FIELDS = gql`
    fragment NoteFields on notes {
        acknowledged_by_admin
        acknowledged_by_shipper
        is_acknowledgement
        note
        note_id
        order_id
        private_to
        requires_acknowledgement
        source_user_type
        source_user_id
        created_at
        invoice_id
        user {
            user_id
            email
            username
        }
    }
`;
