import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { css } from '@emotion/react';
import { colors } from '@/styles';
import { omit } from 'lodash';
import { Grid, Button, FormControl, Select, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { ORDER_READONLY_FIELDS } from '@/constants/readonlyFields';
import { OnwardCard } from './Card';
import { useMutation } from '@apollo/client';
import { asDateInTZ } from '@/utilities/convertToISO';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { format } from 'date-fns';
import { UPSERT_ORDERS } from '@/components/ShipmentForm/graphql/mutations';
import { useFormatters } from '@/components/OrderDetailsPage/Crossdocking/hooks';
import { CardTitle, CardItem, CardSubtitle, CardItemBold } from './blocks';
import { PALLET_STATUSES, RECEIVED_STATUSES } from '@/constants/manifestStatuses';
import { Info } from '@material-ui/icons';
import { SingleDatePicker } from '../ShipmentForm/modals/InputFields';
import { useClientUser } from '@/hooks';
import { formatInTimeZone } from 'date-fns-tz';
import { Link } from 'react-router-dom';
import ItemSection from '../Manifest/cards/ItemSection';
import { PrimaryButton } from '@/styles/blocks';
import { INSERT_MANIFEST } from '../Manifests/graphql';
import { captureException } from '@sentry/react';
import { INSERT_PALLET, UPDATE_PALLETS_MANY } from '../Manifest/graphql';
import StorePallets from '../Manifest/modals/StorePallets';
import PrintLabelsModal from '../Manifest/modals/PrintLabelsModal';

const statusLabelMap = {
    RECEIVED: 'Received',
    NOT_DELIVERED: 'Not Received',
};

export default function WarehousingCard(props) {
    const { user_id, order, warehouse, editable, manifestEditable, warehouseEditable, refetchOrder, setNotification } = props;

    const [openEditor, setOpenEditor] = useState(false);
    const [updates, setUpdates] = useState({});
    const formatters = useFormatters(order);
    const [submitOrder] = useMutation(UPSERT_ORDERS);
    const [hasItemsWithoutInboundManifest, setHasItemsWithoutInboundManifest] = useState(false);
    const [singleOrderManifest, setSingleOrderManifest] = useState(null);
    const [receivingDate, setReceivingDate] = useState();
    const [selectedWarehouse, setSelectedWarehouse] = useState();
    const { client_id, locations: warehouses } = useClientUser();
    const [storingPallets, storePalletsInit] = useState(null);
    const [printingLabels, printLabels] = useState();

    const orderTZ = useMemo(() => {
        return order.pickup_zip ? zipcode_to_timezone.lookup(order.pickup_zip) : 'America/New_York';
    }, [order]);

    const controllerAttr = useMemo(() => {
        return order.warehouse_control_entity === 'SHIPPER' ? 'carrier_id' : 'shipper_id';
    }, [order]);

    const clone = useMemo(() => {
        return {
            ...warehouse,
            ...updates,
        };
    }, [warehouse, updates]);

    const statusLabel = useMemo(() => {
        if (clone?.status === 'RECEIVED') {
            return (
                <CardItemBold
                    className="my-1"
                    css={css`
                        color: ${colors.greens.primary};
                    `}
                >
                    {statusLabelMap[clone.status]}
                </CardItemBold>
            );
        }

        const [received, total] = (order?.itemsByOrderId || []).reduce(
            ([recAcc, totAcc], item) => {
                return [
                    recAcc + (RECEIVED_STATUSES.includes(item.pallet?.warehouse_status) ? item.quantity || 1 : 0),
                    totAcc + (item.quantity || 1),
                ];
            },
            [0, 0]
        );

        if (received > 0) {
            return (
                <CardItemBold
                    className="my-1"
                    css={css`
                        color: ${colors.blues[0]};
                    `}
                >
                    {received}/{total} Received
                </CardItemBold>
            );
        }

        return (
            <CardItemBold
                className="my-1"
                css={css`
                    color: ${colors.greys[3]};
                `}
            >
                Not Received
            </CardItemBold>
        );
    }, [clone, order]);

    const inboundManifests = useMemo(() => {
        const _manifests = {};
        order?.itemsByOrderId.forEach((item) => {
            if (item.manifests) {
                item.manifests.forEach((manifestMapping) => {
                    if (
                        manifestMapping.manifest &&
                        ['WILL_CALL', 'INBOUND', 'CROSS_DOCK'].includes(manifestMapping.manifest.type)
                    ) {
                        const manifestId = manifestMapping.manifest.manifest_id;
                        if (!_manifests[manifestId]) {
                            _manifests[manifestId] = {
                                ...manifestMapping.manifest,
                                items: [],
                            };
                        }
                        const { manifest, ...rest } = manifestMapping;
                        _manifests[manifestId].items.push({
                            ...rest,
                            item: { ...item, order: order },
                        });
                    } else {
                        setHasItemsWithoutInboundManifest(true);
                    }
                });
            } else {
                setHasItemsWithoutInboundManifest(true);
            }
        });
        return Object.values(_manifests);
    }, [order]);

    useEffect(() => {
        if (inboundManifests.length === 1 && !hasItemsWithoutInboundManifest) {
            setSingleOrderManifest(inboundManifests[0]);
        } else {
            setSingleOrderManifest(null);
        }
    }, [inboundManifests, hasItemsWithoutInboundManifest]);

    const canCreateSingleOrderManifest = useMemo(() => {
        if (order?.wh_events) {
            if (order.wh_events.some((event) => event.action && event.action.includes(':ADD_CD'))) {
                return false;
            }
        }

        if (order.itemsByOrderId.some((item) => item?.exceptions?.length > 0)) {
            return false;
        }

        if (inboundManifests?.length > 0) {
            return false;
        }

        return true;
    }, [order, inboundManifests]);

    const [insertManifest, { loading: createLoading }] = useMutation(INSERT_MANIFEST, {
        onError: (error) => {
            console.log(error.message);
            captureException(error);
        },
        onCompleted: ({ created }) => {
            refetchOrder();
        },
    });

    const createSingleOrderManifest = () => {
        const itemIds = order.itemsByOrderId.map((item) => ({ item_id: item.item_id }));

        insertManifest({
            variables: {
                manifest: {
                    client_id,
                    type: 'INBOUND',
                    source: 'MANUAL',
                    items: {
                        data: itemIds,
                    },
                    warehouse_id: selectedWarehouse,
                    receiving_date: receivingDate,
                },
            },
        });
    };

    const [insertPallet, { loading: insertPalletLoading }] = useMutation(INSERT_PALLET, {
        onError: (error) => {
            console.log(error.message);
            captureException(error);
        },
    });

    const [updatePallets, { loading: updatePalletsLoading }] = useMutation(UPDATE_PALLETS_MANY, {
        onError: (error) => {
            console.log(error.message);
            captureException(error);
        },
    });

    const receiveItem = useCallback(
        (item, manifest) => {
            const RECEIVE_EVENTS = ['START:RECEIVING', 'CD_PENDING_PO:PICKING_UP'];
            let event;
            if (
                manifest.items.every(
                    (itemMapping) =>
                        itemMapping.item?.item_id === item.item_id ||
                        RECEIVED_STATUSES.includes(itemMapping.item?.pallet?.warehouse_status)
                )
            ) {
                event = (order?.wh_events || []).reduce(
                    (acc, { __typename, exception, transition, ...rest }) => {
                        if (RECEIVE_EVENTS.includes(rest.action)) {
                            return {
                                ...rest,
                                received_date: new Date().toISOString(),
                                status: 'RECEIVED',
                            };
                        }
                        return acc;
                    },
                    {
                        action: 'START:RECEIVING',
                        order_id: item.order_id,
                        received_date: new Date().toISOString(),
                        status: 'RECEIVED',
                    }
                );
            }
            insertPallet({
                variables: {
                    item_id: item.item_id,
                    pallet: {
                        type: 'SINGLE',
                        warehouse_id: manifest.warehouse_id,
                        warehouse_location: null,
                        warehouse_status: 'RECEIVED',
                    },
                    ...(event ? { events: [event] } : {}),
                },
                onCompleted: () => {
                    refetchOrder();
                },
            });
        },
        [order]
    );

    const receivePallet = (pallets, manifest) => {
        updatePallets({
            variables: {
                updates: pallets.map((pallet) => ({
                    where: { pallet_id: { _eq: pallet.pallet_id } },
                    _set: {
                        type: 'SINGLE',
                        warehouse_id: manifest.warehouse_id,
                        warehouse_location: null,
                        warehouse_status: PALLET_STATUSES.RECEIVED,
                    },
                })),
            },
            onCompleted: () => {
                refetchOrder();
            },
        });
    };

    const storePallets = useCallback((pallets) => {
        return updatePallets({
            variables: {
                updates: pallets.map((pallet) => ({
                    where: { pallet_id: { _eq: pallet.pallet_id } },
                    _set: {
                        warehouse_location: pallet.warehouse_location,
                        warehouse_status: PALLET_STATUSES.STORED,
                        is_pool: pallet.is_pool,
                    },
                })),
            },
            onError: (error) => {
                if (error.message.includes('pallet_locations_unique')) {
                    setNotification({ severity: 'error', message: 'Bin location is already occupied' });
                } else {
                    setNotification(
                        `Failed to store ${pallets.some((pallet) => pallet.type === 'SINGLE') ? 'item' : 'pallet'}`
                    );
                }
            },
        });
    }, []);

    const handleSave = useCallback(() => {
        const { itemsByOrderId, ...remaining } = order;

        submitOrder({
            variables: {
                orders: [
                    {
                        ...omit(remaining, ORDER_READONLY_FIELDS),
                        wh_events: {
                            data: [
                                {
                                    action: 'START:RECEIVING',
                                    notes: clone.notes,
                                    est_received_date: clone.est_received_date,
                                    est_ship_date: clone.est_ship_date,
                                    status: clone.status,
                                    ...(clone.status === 'RECEIVED' && warehouse.status !== 'RECEIVED'
                                        ? {
                                              received_date: new Date().toISOString(),
                                          }
                                        : {}),
                                },
                            ],
                        },
                    },
                ],
                items: [],
                removals: [],
            },
        }).then(() => {
            setOpenEditor(false);
        });
    }, [order, clone, warehouse]);

    const CardRow = ({ children, ...rest }) => {
        return (
            <Grid
                container
                item
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: '0.75rem', marginBottom: '0.75rem' }}
                {...rest}
            >
                {children}
            </Grid>
        );
    };

    return (
        <OnwardCard>
            <CardTitle style={{ display: 'block', marginBottom: '1.5rem' }}>Warehousing</CardTitle>
            <CardRow>
                <Grid item>
                    <CardSubtitle>Status</CardSubtitle>
                </Grid>
                <Grid item>
                    {openEditor ? (
                        <FormControl variant="outlined" size="small">
                            <Select
                                value={clone.status}
                                name="raw-select"
                                onChange={(e) => setUpdates((prev) => ({ ...prev, status: e.target.value }))}
                                MenuProps={{
                                    disableScrollLock: true,
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                {Object.keys(statusLabelMap).map((status) => (
                                    <MenuItem value={status}>{statusLabelMap[status]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <>{statusLabel}</>
                    )}
                </Grid>
            </CardRow>
            <CardRow>
                <Grid item>
                    <CardItem>Estimated Ship Date</CardItem>
                </Grid>
                <Grid item>
                    {openEditor ? (
                        <TextField
                            type="date"
                            variant="outlined"
                            label="Est. Ship Date"
                            InputLabelProps={{ shrink: true }}
                            value={clone.est_ship_date ? format(new Date(clone.est_ship_date), 'yyyy-MM-dd') : ''}
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    dateStr = asDateInTZ(e.target.value, orderTZ).toISOString();
                                    setUpdates((prev) => ({ ...prev, est_ship_date: dateStr }));
                                }
                            }}
                        />
                    ) : (
                        <CardItemBold className="my-1">
                            {clone.est_ship_date ? formatters.dateShort.format(new Date(clone.est_ship_date)) : 'N/A'}
                        </CardItemBold>
                    )}
                </Grid>
            </CardRow>
            <CardRow>
                <Grid item>
                    <CardItem>Estimated Received Date</CardItem>
                </Grid>
                <Grid item>
                    {openEditor ? (
                        <TextField
                            type="date"
                            variant="outlined"
                            label="Est. Received Date"
                            InputLabelProps={{ shrink: true }}
                            value={
                                clone.est_received_date ? format(new Date(clone.est_received_date), 'yyyy-MM-dd') : ''
                            }
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    dateStr = asDateInTZ(e.target.value, orderTZ).toISOString();
                                    setUpdates((prev) => ({ ...prev, est_received_date: dateStr }));
                                }
                            }}
                        />
                    ) : (
                        <CardItemBold className="my-1">
                            {clone.est_received_date
                                ? formatters.dateShort.format(new Date(clone.est_received_date))
                                : 'N/A'}
                        </CardItemBold>
                    )}
                </Grid>
            </CardRow>
            <CardRow>
                <Grid item>
                    <CardItem>Actual Received Date</CardItem>
                </Grid>
                <Grid item>
                    {openEditor && clone.received_date ? (
                        <TextField
                            type="datetime-local"
                            variant="outlined"
                            label="Est. Received Date"
                            InputLabelProps={{ shrink: true }}
                            value={
                                clone.received_date ? format(new Date(clone.received_date), 'yyyy-MM-dd hh:mm:ss') : ''
                            }
                            onChange={(e) => {
                                let dateStr = null;
                                if (e.target.value) {
                                    dateStr = asDateInTZ(e.target.value, orderTZ).toISOString();
                                    setUpdates((prev) => ({ ...prev, received_date: dateStr }));
                                }
                            }}
                        />
                    ) : (
                        <>
                            <CardItemBold className="my-1">
                                {clone.received_date
                                    ? formatters.dateShort.format(new Date(clone.received_date))
                                    : 'Pending'}
                            </CardItemBold>
                        </>
                    )}
                </Grid>
            </CardRow>
            <CardRow>
                <Grid item>
                    <CardItem>Actual Received Time</CardItem>
                </Grid>
                <Grid item>
                    {openEditor && clone.received_date ? (
                        <>
                            <CardItemBold className="my-1">Choose above</CardItemBold>
                        </>
                    ) : (
                        <>
                            <CardItemBold className="my-1">
                                {clone.received_date
                                    ? formatters.timeShort.format(new Date(clone.received_date))
                                    : 'Pending'}
                            </CardItemBold>
                        </>
                    )}
                </Grid>
            </CardRow>

            <CardRow item>
                <CardSubtitle style={{ marginTop: '1.5rem' }}>Notes</CardSubtitle>
            </CardRow>
            <CardRow item>
                {openEditor ? (
                    <TextField
                        variant="outlined"
                        className="w-100"
                        multiline
                        rows={3}
                        value={clone.notes}
                        onChange={(e) => setUpdates((prev) => ({ ...prev, notes: e.target.value }))}
                    />
                ) : (
                    <CardItem>{clone.notes ? clone.notes : 'Add additional notes here'}</CardItem>
                )}
            </CardRow>
            <CardRow style={{ marginTop: '1rem', marginBottom: 0 }}>
                {openEditor ? (
                    <Grid container spacing={2} item sm={12} className="d-flex justify-content-end">
                        <Grid item>
                            <Button onClick={() => setOpenEditor(false)} variant="contained" color="white">
                                <CardItemBold>Cancel</CardItemBold>
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleSave} variant="contained" color="primary">
                                <CardItemBold>Save</CardItemBold>
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {warehouseEditable ? (
                            <Grid item sm={12} className="d-flex flex-column align-items-end">
                                <Button
                                    onClick={() => setOpenEditor(true)}
                                    variant="contained"
                                    color="primary"
                                    className="updateTime-btn"
                                >
                                    <CardItemBold>Update Status</CardItemBold>
                                </Button>
                            </Grid>
                        ) : null}
                    </>
                )}
            </CardRow>
            {manifestEditable && (
                <>
                    <CardRow item>
                        <CardSubtitle style={{ marginTop: '1.5rem' }}>Manifests</CardSubtitle>
                    </CardRow>
                    <CardRow>
                        {singleOrderManifest ? (
                            <>
                                <CardRow>
                                    <CardSubtitle>Inbound Manifest {singleOrderManifest.manifest_number}</CardSubtitle>
                                </CardRow>
                                <CardRow
                                    css={css`
                                        justify-content: flex-start;
                                    `}
                                >
                                    <PrimaryButton onClick={() => printLabels(singleOrderManifest)}>
                                        Print Labels
                                    </PrimaryButton>
                                </CardRow>
                                <CardRow>
                                    <CardItem>
                                        To access all warehousing features for this manifest, click{' '}
                                        <Link
                                            to={`/manifests/${singleOrderManifest.manifest_id}`}
                                            css={css`
                                                color: #59b863;
                                                font-weight: 500;
                                            `}
                                        >
                                            Here
                                        </Link>
                                    </CardItem>
                                </CardRow>
                                {order.itemsByOrderId.map((item) => (
                                    <ItemSection
                                        key={item.item_id}
                                        manifest={singleOrderManifest}
                                        item={item}
                                        order={order}
                                        options={{ editOrderModal: true }}
                                        callbacks={{
                                            receiveItem: receiveItem,
                                            receivePallet: receivePallet,
                                            storePalletsInit: storePalletsInit,
                                        }}
                                        editable={manifestEditable}
                                    />
                                ))}
                            </>
                        ) : inboundManifests.length === 0 ? (
                            <>
                                <CardRow>
                                    <SingleDatePicker
                                        label="Receiving Date"
                                        value={
                                            receivingDate
                                                ? formatInTimeZone(new Date(receivingDate), 'UTC', 'yyyy-MM-dd')
                                                : null
                                        }
                                        onChange={(e) => {
                                            setReceivingDate(asDateInTZ(e.target.value, 'UTC').toISOString());
                                        }}
                                    />
                                </CardRow>
                                <CardRow>
                                    <TextField
                                        variant="outlined"
                                        select
                                        fullWidth
                                        label="Warehouse"
                                        name="warehouse_id"
                                        InputLabelProps={{ shrink: true }}
                                        value={selectedWarehouse || ''}
                                        onChange={(e) => setSelectedWarehouse(e.target.value)}
                                        css={css`
                                            margin-top: 1rem;
                                            margin-right: 8px;
                                        `}
                                    >
                                        {warehouses.map((warehouse) => (
                                            <MenuItem key={warehouse.location_id} value={warehouse.location_id}>
                                                {warehouse.location_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </CardRow>

                                <CardRow
                                    css={css`
                                        justify-content: flex-start;
                                    `}
                                >
                                    <PrimaryButton
                                        onClick={() => createSingleOrderManifest()}
                                        disabled={!canCreateSingleOrderManifest || !receivingDate || !selectedWarehouse}
                                    >
                                        Receive as single order
                                    </PrimaryButton>
                                    <Tooltip
                                        style={{ color: '#59B863', marginLeft: '5px' }}
                                        title={`Select a receiving date and warehouse, then press to create a single inbound manifest for the whole order. ${
                                            canCreateSingleOrderManifest
                                                ? ''
                                                : 'Not available for cross-docked orders, or if some items are already in an inbound manifest or have exceptions. See the Warehousing section for more options.'
                                        }`}
                                        placement="right"
                                    >
                                        <Info />
                                    </Tooltip>
                                </CardRow>
                            </>
                        ) : (
                            <CardRow>
                                <CardItem>
                                    This order has items on the following inbound manifest(s). Follow the links for full
                                    warehousing features:{' '}
                                    {inboundManifests.map((manifest, i) => (
                                        <React.Fragment key={i}>
                                            {i > 0 && ', '}
                                            <Link
                                                to={`/manifests/${manifest.manifest_id}`}
                                                css={css`
                                                    color: #59b863;
                                                    font-weight: 500;
                                                `}
                                            >
                                                Manifest {manifest.manifest_number}
                                            </Link>
                                        </React.Fragment>
                                    ))}
                                </CardItem>
                            </CardRow>
                        )}
                    </CardRow>
                </>
            )}
            <StorePallets
                pallets={storingPallets}
                warehouseId={singleOrderManifest?.warehouse_id}
                callbacks={{ storePalletsInit: storePalletsInit, storePallets: storePallets }}
                loading={{ palletsLoading: updatePalletsLoading }}
            />
            <PrintLabelsModal
                printingManifest={printingLabels}
                loading={{ updateLoading: updatePalletsLoading || insertPalletLoading || createLoading }}
                callbacks={{ printLabels: printLabels }}
            />
        </OnwardCard>
    );
}
