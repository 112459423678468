import { gql } from '@apollo/client';

export const DRIVER_PAYMENT_FIELDS = gql`
    fragment DriverPaymentFields on driver_payments {
        payment_id
        client_id
        driver_id
        pay_period_start
        pay_period_end
        status
        rate_overrides
        created_at
    }
`;

export const DRIVER_PAYMENT_MAPPINGS = gql`
    fragment DriverPaymentMappings on driver_payment_mappings {
        mapping_id
        payment_id
        route_id
        order_id
    }
`;
