import React, { useMemo, useContext, useState, useEffect } from 'react';
import zipcode_to_timezone from 'zipcode-to-timezone';
import { css } from '@emotion/react';
import { Grid, MenuItem, Tooltip } from '@material-ui/core';
import { asDateInTZ } from '@/utilities/convertToISO';
import { useWarehouseAggregate } from '@onward-delivery/core';
import { PrimaryButton, Row } from '@/styles/blocks';
import styled from '@emotion/styled';
import { SingleDatePicker, WarehouseEntities } from '../InputFields';
import { useSwappedAttributes } from '../../hooks';
import { TextField, OnwardToggle, RadioLabelInfo } from '../../blocks';
import { ModalContext } from './';
import { formatInTimeZone } from 'date-fns-tz';
import { Info } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useClientUser } from '@/hooks';
import { Body2, Body1 } from '@/components/Manifest/blocks';
import PrintLabelsModal from '@/components/Manifest/modals/PrintLabelsModal';

const WAREHOUSE_FIELDS = [
    'warehouse_estimated_ship_date',
    'warehouse_estimated_delivery_date',
    'warehouse_delivery_status',
    'warehouse_delivery_notes',
];

const HR = styled.div`
    margin-top: 32px;
    margin-bottom: 32px;
    border: 1px solid rgba(76, 76, 76, 0.5);
`;

const WarehouseTab = ({ opt }) => {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const [enableWarehouseOverride, setEnableWarehouse] = useState(null);
    const { order, hasError, errors } = modalState;
    const warehouse = useWarehouseAggregate(order?.wh_events);
    const { zip } = useSwappedAttributes(order);
    const { client_id, locations: warehouses } = useClientUser();
    const [receivingDate, setReceivingDate] = useState();
    const [selectedWarehouse, setSelectedWarehouse] = useState();
    const [singleOrderManifest, setSingleOrderManifest] = useState(null);
    const [hasItemsWithoutInboundManifest, setHasItemsWithoutInboundManifest] = useState(false);
    const [printingLabels, printLabels] = useState();

    const tz = useMemo(() => {
        return order[zip] ? zipcode_to_timezone.lookup(order[zip]) : 'America/New_York';
    }, [order, zip]);

    const enableWarehouse = useMemo(() => {
        return order?.warehouse_delivery_status || warehouse || enableWarehouseOverride;
    }, [enableWarehouseOverride, warehouse]);

    const inboundManifests = useMemo(() => {
        const _manifests = {};
        order?.itemsByOrderId.forEach((item) => {
            if (item.manifests) {
                item.manifests.forEach((manifestMapping) => {
                    if (
                        manifestMapping.manifest &&
                        ['WILL_CALL', 'INBOUND', 'CROSS_DOCK'].includes(manifestMapping.manifest.type)
                    ) {
                        const manifestId = manifestMapping.manifest.manifest_id;
                        if (!_manifests[manifestId]) {
                            _manifests[manifestId] = {
                                ...manifestMapping.manifest,
                                items: [],
                            };
                        }
                        const { manifest, ...rest } = manifestMapping;
                        _manifests[manifestId].items.push({
                            ...rest,
                            item: { ...item, order: order },
                        });
                    } else {
                        setHasItemsWithoutInboundManifest(true);
                    }
                });
            } else {
                setHasItemsWithoutInboundManifest(true);
            }
        });
        return Object.values(_manifests);
    }, [order]);

    useEffect(() => {
        if (inboundManifests.length === 1 && !hasItemsWithoutInboundManifest) {
            setSingleOrderManifest(inboundManifests[0]);
        } else {
            setSingleOrderManifest(null);
        }
    }, [inboundManifests, hasItemsWithoutInboundManifest]);

    const canCreateSingleOrderManifest = useMemo(() => {
        if (order?.wh_events) {
            if (order.wh_events.some((event) => event.action && event.action.includes(':ADD_CD'))) {
                return false;
            }
        }

        if (order.itemsByOrderId.some((item) => item?.exceptions?.length > 0)) {
            return false;
        }

        if (inboundManifests?.length > 0) {
            return false;
        }

        return true;
    }, [order, inboundManifests]);

    const manifestContent = (
        <>
            <HR />
            {singleOrderManifest ? (
                <>
                    <Row>
                        <Body2>Inbound Manifest {singleOrderManifest.manifest_number}</Body2>
                    </Row>
                    <Row>
                        <PrimaryButton onClick={() => printLabels(singleOrderManifest)}>Print Labels</PrimaryButton>
                    </Row>
                    <Row>
                        <Body1>
                            To access all warehousing features for this manifest, click{' '}
                            <Link
                                to={`/manifests/${singleOrderManifest.manifest_id}`}
                                css={css`
                                    color: #59b863;
                                    font-weight: 500;
                                `}
                            >
                                here,
                            </Link>{' '}
                            or see the warehousing card in the order details page.
                        </Body1>
                    </Row>
                </>
            ) : inboundManifests.length === 0 ? (
                <>
                    <Row
                        css={css`
                            width: 50%;
                        `}
                    >
                        <SingleDatePicker
                            label="Receiving Date"
                            value={
                                receivingDate ? formatInTimeZone(new Date(receivingDate), 'UTC', 'yyyy-MM-dd') : null
                            }
                            onChange={(e) => {
                                setReceivingDate(asDateInTZ(e.target.value, 'UTC').toISOString());
                            }}
                        />
                    </Row>
                    <Row
                        css={css`
                            width: 50%;
                        `}
                    >
                        <TextField
                            variant="outlined"
                            select
                            fullWidth
                            label="Warehouse"
                            name="warehouse_id"
                            InputLabelProps={{ shrink: true }}
                            value={selectedWarehouse || ''}
                            onChange={(e) => setSelectedWarehouse(e.target.value)}
                            css={css`
                                margin-top: 1rem;
                                margin-right: 8px;
                            `}
                        >
                            {warehouses.map((warehouse) => (
                                <MenuItem key={warehouse.location_id} value={warehouse.location_id}>
                                    {warehouse.location_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Row>

                    <Row>
                        <OnwardToggle
                            css={css`
                                margin: 0;
                            `}
                            value={modalState.createSingleOrderManifest}
                            disabled={!canCreateSingleOrderManifest || !receivingDate || !selectedWarehouse}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    const itemIds = order.itemsByOrderId.map((item) => ({ item_id: item.item_id }));
                                    modalState.setCreateSingleOrderManifest({
                                        client_id,
                                        type: 'INBOUND',
                                        source: 'MANUAL',
                                        items: {
                                            data: itemIds,
                                        },
                                        warehouse_id: selectedWarehouse,
                                        receiving_date: receivingDate,
                                    });
                                } else {
                                    modalState.setCreateSingleOrderManifest(null);
                                }
                            }}
                        />
                        <RadioLabelInfo
                            css={css`
                                color: #000;
                                padding-top: 3px;
                                margin-left: 3px;
                            `}
                        >
                            Receive as single order
                        </RadioLabelInfo>
                        <Tooltip
                            style={{ color: '#59B863', marginLeft: '5px' }}
                            title={`Select a receiving date and warehouse, then toggle on to create a single manifest for the whole order when you click "Done". ${
                                canCreateSingleOrderManifest
                                    ? ''
                                    : 'Not available for cross-docked orders, or if some items are already in an inbound manifest or have exceptions. See the Warehousing section for more options.'
                            }`}
                            placement="right"
                        >
                            <Info />
                        </Tooltip>
                    </Row>
                </>
            ) : (
                <Row>
                    <Body1>
                        This order has items on the following inbound manifest(s). Follow the links for full warehousing
                        features:{' '}
                        {inboundManifests.map((manifest, i) => (
                            <React.Fragment key={i}>
                                {i > 0 && ', '}
                                <Link
                                    to={`/manifests/${manifest.manifest_id}`}
                                    css={css`
                                        color: #59b863;
                                        font-weight: 500;
                                    `}
                                >
                                    Manifest {manifest.manifest_number}
                                </Link>
                            </React.Fragment>
                        ))}
                    </Body1>
                </Row>
            )}
        </>
    );

    const enableWarehouseContent = (
        <>
            <Row
                css={css`
                    width: 50%;
                `}
            >
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-basis: 0;
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <WarehouseEntities
                        value={order.warehouse_control_entity || 'CARRIER'}
                        label="Status Update Ownership"
                        helperText="Who will have the ability to update the warehouse status once order is submitted"
                        onChange={(e) => {
                            callbacks.modifyOrder({
                                warehouse_control_entity: e.target.value,
                            });
                        }}
                    />
                </Grid>
            </Row>
            <Row>
                <Grid
                    container
                    direction="column"
                    css={css`
                        flex-basis: 0;
                        flex: 1;
                        margin-right: 16px;
                    `}
                >
                    <SingleDatePicker
                        label="Estimated Ship Date"
                        value={
                            order?.warehouse_estimated_ship_date || warehouse?.est_ship_date
                                ? formatInTimeZone(
                                      new Date(order?.warehouse_estimated_ship_date || warehouse?.est_ship_date),
                                      tz,
                                      'yyyy-MM-dd'
                                  )
                                : null
                        }
                        error={
                            !order?.warehouse_estimated_ship_date && !warehouse?.est_ship_date && order?.is_middle_mile
                        }
                        onChange={(e) => {
                            let dateStr = null;
                            if (e.target.value) {
                                dateStr = asDateInTZ(e.target.value, tz).toISOString();
                            }

                            callbacks.modifyOrder({
                                warehouse_estimated_ship_date: dateStr,
                            });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    direction="column"
                    css={css`
                        flex-basis: 0;
                        flex: 1;
                        margin-right: 0;
                    `}
                >
                    <SingleDatePicker
                        label="Estimated Delivery Date"
                        value={
                            order?.warehouse_estimated_delivery_date || warehouse?.est_received_date
                                ? formatInTimeZone(
                                      new Date(
                                          order?.warehouse_estimated_delivery_date || warehouse?.est_received_date
                                      ),
                                      tz,
                                      'yyyy-MM-dd'
                                  )
                                : null
                        }
                        onChange={(e) => {
                            let dateStr = null;
                            if (e.target.value) {
                                dateStr = asDateInTZ(e.target.value, tz).toISOString();
                            }

                            callbacks.modifyOrder({
                                warehouse_estimated_delivery_date: dateStr,
                            });
                        }}
                    />
                </Grid>
            </Row>
            <Row>
                <TextField
                    label="Warehouse Notes"
                    variant="outlined"
                    name="desc"
                    fullWidth
                    value={order?.warehouse_delivery_notes || warehouse?.notes || ''}
                    onChange={(e) => callbacks.modifyOrder({ warehouse_delivery_notes: e.target.value })}
                    multiline
                    rows={5}
                />
            </Row>
        </>
    );

    return (
        <Grid container direction="column">
            <Row
                css={css`
                    margin-bottom: 32px;
                `}
            >
                <Grid
                    container
                    item
                    css={css`
                        flex: 0;
                        flex-basis: 0;
                        justify-content: center;
                    `}
                >
                    <OnwardToggle
                        css={css`
                            margin: 0;
                        `}
                        value={enableWarehouse}
                        onChange={(e) => {
                            callbacks.modifyOrder({
                                warehouse_delivery_status: e.target.checked ? 'NOT_DELIVERED' : null,
                                ...(!e.target.checked
                                    ? Object.fromEntries(WAREHOUSE_FIELDS.map((attr) => [attr, null]))
                                    : {}),
                            });

                            setEnableWarehouse(e.target.checked);
                        }}
                    />
                </Grid>
                <Grid
                    direction="column"
                    container
                    css={css`
                        flex: 1;
                        flex-basis: 0;
                        margin-left: 12px;
                        justify-content: center;
                    `}
                >
                    <RadioLabelInfo
                        css={css`
                            color: #000;
                        `}
                    >
                        Track warehouse status
                    </RadioLabelInfo>
                </Grid>
            </Row>
            {enableWarehouse ? enableWarehouseContent : null}
            {opt?.enableSingleOrderManifest ? manifestContent : null}
            <PrintLabelsModal
                printingManifest={printingLabels}
                loading={{ updateLoading: false }}
                callbacks={{ printLabels: printLabels }}
            />
        </Grid>
    );
};

export default WarehouseTab;
