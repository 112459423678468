import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
    Grid,
    Button,
    createTheme,
    Dialog,
    DialogActions,
    DialogTitle,
    Typography,
    TextField,
    InputAdornment,
} from '@material-ui/core';
import { ModalContext } from '.';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { OnwardCheckbox } from '../../blocks';
import { SectionSubtitle2 } from '@/styles/blocks';
import SearchIcon from '@material-ui/icons/Search';

function AdditionalDocuments() {
    const { state: modalState, callbacks } = useContext(ModalContext);
    const [search, setSearch] = useState('');

    const order = modalState?.order;

    const { documents } = useClientUser();

    const searchedDocuments = useMemo(() => {
        const lowerCaseSearch = search.toLowerCase();
        return documents.filter(
            (doc) =>
                doc.name.toLowerCase().includes(lowerCaseSearch) ||
                doc.type.toLowerCase().includes(lowerCaseSearch) ||
                order?.documents?.find((d) => d.document_id === doc.document_id)
        );
    }, [documents, search, order]);

    const allChecked = useMemo(() => {
        return (searchedDocuments || [])?.every((doc) =>
            (order?.documents || [])?.find((d) => d.document_id === doc.document_id)
        );
    }, [searchedDocuments, order]);

    if (!documents?.length) {
        return <></>;
    }

    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid item>
                    <SectionSubtitle2>Attach SOP / Assembly Instructions</SectionSubtitle2>
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Search..."
                        value={search || ''}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        css={css`
                            background-color: white;
                            width: 100%;
                            margin-top: 0.2rem;
                        `}
                    />
                </Grid>
                <hr
                    css={css`
                        margin: 0;
                    `}
                />
                <Grid
                    container
                    css={css`
                        padding: 14px;
                        align-items: center;
                    `}
                >
                    <Grid item xs={1}>
                        <OnwardCheckbox
                            checked={allChecked}
                            onChange={() => {
                                if (allChecked) {
                                    callbacks.modifyOrder({
                                        documents: [],
                                    });
                                } else {
                                    callbacks.modifyOrder({
                                        documents: searchedDocuments.map((doc) => ({
                                            document_id: doc.document_id,
                                            ...(order.order_id ? { order_id: order.order_id } : {}),
                                        })),
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Document Type
                        </Typography>
                    </Grid>
                </Grid>

                {searchedDocuments.map((doc) => (
                    <>
                        <hr
                            css={css`
                                margin: 0;
                            `}
                        />

                        <Grid
                            container
                            css={css`
                                padding: 14px;
                                align-items: center;
                            `}
                        >
                            <Grid item xs={1}>
                                <OnwardCheckbox
                                    checked={order?.documents?.find((d) => d.document_id === doc.document_id) || false}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            const newDocs = [
                                                ...(order?.documents?.length ? order.documents : []),
                                                {
                                                    document_id: doc.document_id,
                                                    ...(order.order_id ? { order_id: order.order_id } : {}),
                                                },
                                            ];
                                            callbacks.modifyOrder({
                                                documents: newDocs,
                                            });
                                        } else {
                                            let newDocs = [...(order?.documents?.length ? order.documents : [])];
                                            const idxToRemove = newDocs.findIndex(
                                                (d) => d.document_id === doc.document_id
                                            );
                                            newDocs.splice(idxToRemove, 1);
                                            callbacks.modifyOrder({
                                                documents: newDocs,
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{doc.name}</Typography>
                            </Grid>
                            <Grid
                                container
                                xs={2}
                                css={css`
                                    justify-content: space-between;
                                `}
                            >
                                <Grid item>
                                    <Typography>{doc.type}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    );
}

export default AdditionalDocuments;
