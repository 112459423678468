const DOW_RATES = [
    'SUNDAY_RATE',
    'MONDAY_RATE',
    'TUESDAY_RATE',
    'WEDNESDAY_RATE',
    'THURSDAY_RATE',
    'FRIDAY_RATE',
    'SATURDAY_RATE',
];

const getOrderRates = (order, rates) => {
    const breakdown =
        (order.oms ? order.price_breakdown?.internalBreakdown : order.price_breakdown?.carrierBreakdown) || {};

    const freightCharge = {
        type: 'FREIGHT_CHARGE',
        base: breakdown?.base_charge || 0,
        rate: rates['FREIGHT_CHARGE']?.[0]?.rate || 0,
    };

    const accessorials = (breakdown?.accessorials || []).map((accessorial) => {
        const baseRate = accessorial.rate * accessorial.quantity;
        const driverRate = rates[accessorial.type]?.[0]?.rate || 0;
        return {
            type: accessorial.type,
            base: baseRate,
            rate: driverRate,
        };
    });

    return [freightCharge, ...accessorials].map((charge) => ({ ...charge, po_number: order.po_number }));
};

const dailyFlat = ({ rates, orders, routes, date, overrides }) => {
    let accessorials = [];

    // Calculated Rate
    for (const route of routes || []) {
        for (const mapping of route.orders || []) {
            accessorials.push(...getOrderRates(mapping.order, rates));
        }
    }
    for (const order of orders || []) {
        accessorials.push(...getOrderRates(order, rates));
    }
    const calculatedRate = accessorials.reduce((sum, accessorial) => {
        return sum + accessorial.base * accessorial.rate;
    }, 0);

    // Final Rate
    if (overrides?.length > 0) {
        accessorials = overrides;
    }
    const totalRate = accessorials.reduce((sum, accessorial) => {
        return sum + accessorial.base * accessorial.rate;
    }, 0);

    // Target Rate
    const dow = new Date(date).getDay();
    const targetRate = rates[DOW_RATES?.[dow]]?.[0]?.rate || 0;

    return {
        accessorials,
        target_rate: targetRate,
        calculated_rate: calculatedRate,
        adjustments_rate: totalRate - calculatedRate,
        final_rate: totalRate,
    };
};

const dailyMileage = ({ rates, orders, routes, date, overrides }) => {
    let accessorials = [];
    let mileage = 0;

    // Calculated Rate
    for (const route of routes || []) {
        const routeMiles = parseFloat(route.estimated_driving_distance || '0');
        if (isFinite(routeMiles)) mileage += routeMiles;
        for (const mapping of route.orders || []) {
            accessorials.push(...getOrderRates(mapping.order, rates));
        }
    }
    for (const order of orders || []) {
        mileage += order.miles || 0;
        accessorials.push(...getOrderRates(order, rates));
    }
    const calculatedRate = accessorials.reduce((sum, accessorial) => {
        return sum + accessorial.base * accessorial.rate;
    }, 0);

    // Final Rate
    if (overrides?.length > 0) {
        accessorials = overrides;
    }
    const totalRate = accessorials.reduce((sum, accessorial) => {
        return sum + accessorial.base * accessorial.rate;
    }, 0);

    // Target Rate
    const dow = new Date(date).getDay();
    const targetRate = rates[DOW_RATES?.[dow]]?.find((rate) => mileage >= rate.min && mileage < rate.max)?.rate || 0;

    return {
        accessorials,
        target_rate: targetRate,
        calculated_rate: calculatedRate,
        adjustments_rate: totalRate - calculatedRate,
        final_rate: totalRate,
        mileage: mileage,
    };
};

export const driverPricing = (algo_type, params) => {
    switch (algo_type) {
        case 'DRIVER_DAILY_FLAT':
            return dailyFlat(params);
        case 'DRIVER_DAILY_MILEAGE':
            return dailyMileage(params);
    }

    return {};
};
