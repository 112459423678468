import { useClientUser } from '@/hooks';
import { enrichManifest } from '@/utilities/enrichManifest';
import { useMutation, useQuery } from '@apollo/client';
import useAction from '@/utilities/useQuery';
import { captureException } from '@sentry/react';
import { createContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useExceptionCallbacks } from '@/components/OrderDetailsPage/hooks';
import { createOrderJob } from '@/components/ShipmentForm/queries/createOrderJob';
import {
    INSERT_EXCEPTION_EVENT,
    UPDATE_EXCEPTION,
    INSERT_EXCEPTION,
} from '@/components/OrderDetailsPage/graphql/mutations';
import { RESOLVE_EXCEPTION } from '@/components/admin/AdminExceptions/graphql';
import {
    DELETE_MANIFEST,
    GET_MANIFEST,
    INSERT_EMPTY_PALLET,
    INSERT_PALLET,
    INSERT_PALLETS_MANY,
    UPDATE_ITEM_PALLETS_MANY,
    UPDATE_MANIFEST,
    UPDATE_ORDERS,
    UPDATE_PALLET,
    UPDATE_PALLETS_MANY,
} from './graphql';
import { useManifestCallbacks } from './hooks';

export const Context = createContext();

const ContextProvider = ({ children }) => {
    const { user_id } = useClientUser();
    const { manifest_id } = useParams();
    const [notification, setNotification] = useState({});
    const [editingManifest, editManifest] = useState(null);
    const [storingPallets, storePalletsInit] = useState(null);
    const [palletLog, openPalletLog] = useState(null);
    const [consolidatingPallets, consolidatePalletsInit] = useState(null);
    const [stagingPallets, stagePalletsInit] = useState(null);
    const [renamingPallet, renamePalletInit] = useState(null);
    const [editingException, editException] = useState(null);
    const [resolveExceptionParams, setResolveExceptionParams] = useState({});
    const [printingLabels, printLabels] = useState();
    const [editable, setEditable] = useState(false);

    const navigate = useNavigate();

    const { data, loading: queryLoading } = useQuery(GET_MANIFEST, {
        variables: { manifest_id },
        skip: !manifest_id,
        fetchPolicy: 'cache-first',
    });

    const manifest = useMemo(() => {
        if (data?.manifest) {
            if (data.manifest.client_id === user_id) {
                setEditable(true);
            }
            return enrichManifest(data.manifest);
        }
        return {};
    }, [data]);

    const onError = (msg) => (error) => {
        console.error(error);
        console.log(error);
        console.log(error.message);
        captureException(error);
        setNotification({
            severity: 'error',
            message: msg || 'Something went wrong',
        });
    };

    const [updateManifest, { loading: updateLoading }] = useMutation(UPDATE_MANIFEST, {
        onError: onError('Failed to update manifest'),
    });
    const [deleteManifest, { loading: deleteLoading }] = useMutation(DELETE_MANIFEST, {
        onError: onError('Failed to delete manifest'),
        onCompleted: () => {
            navigate('/manifests');
        },
    });
    const [insertPallet, { loading: insertPalletLoading }] = useMutation(INSERT_PALLET, {
        onError: onError('Failed to receive item'),
    });
    const [updateItemPallets, { loading: updateItemPalletsLoading }] = useMutation(UPDATE_ITEM_PALLETS_MANY, {
        onError: onError('Failed to update pallets'),
        refetchQueries: [GET_MANIFEST],
        awaitRefetchQueries: true,
    });
    const [updatePallet, { loading: updatePalletLoading }] = useMutation(UPDATE_PALLET, {
        onError: onError('Failed to update pallet'),
    });
    const [updatePallets, { loading: updatePalletsLoading }] = useMutation(UPDATE_PALLETS_MANY, {
        onError: onError('Failed to update pallets'),
    });
    const [insertEmptyPallet, { loading: insertEmptyPalletLoading }] = useMutation(INSERT_EMPTY_PALLET, {
        onError: onError('Failed to add new pallet'),
    });
    const [insertException, { loading: insertExceptionLoading }] = useMutation(INSERT_EXCEPTION, {
        update: (cache, { data: { created } }) => {
            cache.updateQuery(
                {
                    query: GET_MANIFEST,
                    variables: { manifest_id },
                },
                (data) => {
                    const clone = { ...data?.manifest };
                    clone.items = [...clone.items];

                    created.returning.forEach((mapping) => {
                        const idx = clone.items.findIndex((item) => item.item_id === mapping.item_id);

                        clone.items.splice(idx, 1, {
                            ...clone.items[idx],
                            item: {
                                ...clone.items[idx].item,
                                exceptions: [...clone.items[idx].item.exceptions, mapping],
                            },
                        });
                    });

                    return {
                        manifest: clone,
                    };
                }
            );
        },
        onError: onError('Failed to report exception'),
    });
    const [updateException, { loading: updateExceptionLoading }] = useMutation(UPDATE_EXCEPTION, {
        update: (cache, { data: { updated } }) => {
            cache.updateQuery(
                {
                    query: GET_MANIFEST,
                    variables: { manifest_id },
                },
                (data) => {
                    const clone = { ...data?.manifest };

                    clone.items = [...clone.items];
                    clone.items = clone.items.map((item) => {
                        const clone = [...item.item.exceptions];
                        const idx = clone.findIndex(
                            (ex) => ex.exception.exception_id === updated.returning[0].exception_id
                        );

                        if (idx >= 0) {
                            const next = {
                                ...clone[idx],
                                exception: {
                                    ...clone[idx].exception,
                                    ...updated.returning[0].exception,
                                },
                            };

                            clone.splice(idx, 1, next);
                        }

                        return {
                            ...item,
                            item: {
                                ...item.item,
                                exceptions: clone,
                            },
                        };
                    });

                    return {
                        manifest: clone,
                    };
                }
            );
        },
        onError: onError('Failed to report exception'),
    });
    const [insertExceptionEvent, { loading: insertExceptionEventLoading }] = useMutation(INSERT_EXCEPTION_EVENT, {
        refetchQueries: [GET_MANIFEST],
        awaitRefetchQueries: true,
        onError: onError('Failed to report exception'),
    });
    const [resolveException, { loading: resolveInflight }] = useMutation(RESOLVE_EXCEPTION, {
        update(cache, { data: { order, exception, items_removed } }) {
            cache.updateQuery(
                {
                    query: GET_MANIFEST,
                    variables: { manifest_id },
                },
                (data) => {
                    const clone = { ...data?.manifest };
                    const removed = Object.fromEntries(items_removed.returning.map((item) => [item.item_id, true]));
                    const [resolved] = exception.returning;

                    clone.items = [...clone.items];

                    clone.items = clone.items
                        .filter((item) => !removed[item.item_id])
                        .map((item) => {
                            const clone = [...item.item.exceptions];
                            const idx = clone.findIndex((ex) => ex.exception.exception_id === resolved.exception_id);

                            if (idx >= 0) {
                                const updated = {
                                    ...clone[idx],
                                    exception: {
                                        ...clone[idx].exception,
                                        status: resolved.status,
                                    },
                                };

                                clone.splice(idx, 1, updated);
                            }

                            return {
                                ...item,
                                item: {
                                    ...item.item,
                                    exceptions: clone,
                                },
                            };
                        });

                    return {
                        manifest: clone,
                    };
                }
            );
        },
        onError: (err) => {
            onError('Failed to resolve exception');
        },
    });
    const [updateOrders, { loading: updateOrdersLoading }] = useMutation(UPDATE_ORDERS, {
        refetchQueries: [GET_MANIFEST],
        awaitRefetchQueries: true,
        onError: onError('Failed to update orders'),
    });

    const [submitOrders, { loading: submitInflight }] = useAction(createOrderJob, {
        onError: (err) => {
            onError('Failed to resolve exception');
        },
    });

    const callbacks = useManifestCallbacks(
        { manifest },
        {
            setNotification,
            onError,
            insertPallet,
            updateItemPallets,
            updatePallet,
            updatePallets,
            insertException,
            updateException,
            updateOrders,
        }
    );

    const { createException } = useExceptionCallbacks(
        { order: editingException?.order || {}, exceptionParams: editingException },
        { insertExceptionEvent, insertException, updateException }
    );

    return (
        <Context.Provider
            value={{
                state: {
                    manifest,
                    editable,
                    notification,
                    editingManifest,
                    storingPallets,
                    renamingPallet,
                    palletLog,
                    consolidatingPallets,
                    stagingPallets,
                    editingException,
                    printingLabels,
                    resolveExceptionParams,
                },
                loading: {
                    resolveExceptionLoading: submitInflight || resolveInflight,
                    queryLoading,
                    updateLoading,
                    deleteLoading,
                    palletLoading:
                        insertPalletLoading ||
                        updatePalletLoading ||
                        updatePalletsLoading ||
                        updateItemPalletsLoading ||
                        insertEmptyPalletLoading,
                    exceptionLoading: updateExceptionLoading || insertExceptionLoading || insertExceptionEventLoading,
                    ordersLoading: updateOrdersLoading,
                },
                callbacks: {
                    ...callbacks,
                    clearNotification: () => setNotification({}),
                    editManifest,
                    saveManifest: ({ manifest_id, ...update }) =>
                        updateManifest({ variables: { manifest_id, update } }),
                    deleteManifest: ({ manifest_id }) => deleteManifest({ variables: { manifest_id } }),
                    storePalletsInit,
                    renamePalletInit,
                    openPalletLog,
                    consolidatePalletsInit,
                    insertEmptyPallet,
                    stagePalletsInit,
                    editException,
                    printLabels,
                    submitOrders,
                    setResolveExceptionParams,
                    resolveException,
                    createException,
                },
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const withContext = (Component) => (props) =>
    (
        <ContextProvider>
            <Component {...props} />
        </ContextProvider>
    );
