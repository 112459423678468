import { DRIVER_PAYMENT_FIELDS, DRIVER_PAYMENT_MAPPINGS } from '@/graphql/fragments/driver_payments';
import { DRIVER_TARIFF_FIELDS } from '@/graphql/fragments/driver_tariffs';
import { PRICING_TARIFF_FIELDS } from '@/graphql/fragments/pricing_tariffs';
import { RATE_FIELDS } from '@/graphql/fragments/rates';
import { gql } from '@apollo/client';

export const GET_DRIVER_TARIFFS = gql`
    ${DRIVER_TARIFF_FIELDS}
    ${PRICING_TARIFF_FIELDS}
    ${RATE_FIELDS}

    query GetDriverTariffs($client_id: uuid!) {
        driver_tariffs: driver_tariff_mappings(where: { client_id: { _eq: $client_id } }) {
            ...DriverTariffFields
            tariff {
                ...PricingTariffFields
                rates {
                    ...RateFields
                }
            }
        }
    }
`;

export const GET_DRIVER_PAYMENTS = gql`
    ${DRIVER_PAYMENT_FIELDS}
    ${DRIVER_PAYMENT_MAPPINGS}

    query GetDriverPayments($where: driver_payments_bool_exp!) {
        driver_payments(where: $where, order_by: [{ created_at: desc }, { driver: { username: desc } }], limit: 25) {
            ...DriverPaymentFields
            driver {
                teammate_id
                username
            }
            mappings {
                ...DriverPaymentMappings
                order {
                    order_id
                    po_number
                    oms
                    carrier_rate
                    order_revenue
                    price_breakdown
                }
                route {
                    route_id
                    route_number
                    estimated_driving_distance
                    orders {
                        mapping_id
                        order {
                            order_id
                            po_number
                            oms
                            price_breakdown
                            miles
                        }
                    }
                }
            }
        }
    }
`;
