import { gql } from '@apollo/client';

import { PRICING_TARIFF_FIELDS } from '@/graphql/fragments/pricing_tariffs';
import { PRICING_OVERRIDE_FIELDS } from '@/graphql/fragments/pricing_overrides';
import { RATE_FIELDS } from '@/graphql/fragments/rates';
import { SUBREGION_BASE_FIELDS } from '@/graphql/queries/subregions';
import { DRIVER_TARIFF_FIELDS } from '@/graphql/fragments/driver_tariffs';

export const CREATE_TARIFF = gql`
    ${PRICING_TARIFF_FIELDS}

    mutation CreateTariff($tariffs: [pricing_tariffs_insert_input!]!) {
        tariffs: insert_pricing_tariffs(objects: $tariffs) {
            returning {
                ...PricingTariffFields
            }
        }
    }
`;

export const DELETE_TARIFF = gql`
    ${PRICING_TARIFF_FIELDS}

    mutation DeleteTariff($tariff_id: uuid!) {
        deleted: delete_pricing_tariffs_by_pk(tariff_id: $tariff_id) {
            tariff_id
        }
    }
`;

export const UPSERT_RATES = gql`
    ${RATE_FIELDS}

    mutation UpsertRates($rates: [rates_insert_input!]!, $tariff_id: uuid!, $update: pricing_tariffs_set_input = {}) {
        tariff: update_pricing_tariffs_by_pk(pk_columns: { tariff_id: $tariff_id }, _set: $update) {
            tariff_id
            name
        }

        stale: delete_rates(where: { tariff_id: { _eq: $tariff_id } }) {
            affected_rows
        }

        updated: insert_rates(objects: $rates) {
            ...RateFields
        }
    }
`;

export const UPSERT_PRICING_OVERRIDES = gql`
    ${PRICING_OVERRIDE_FIELDS}

    mutation UpsertPricingOverrides($override: upsert_pricing_overrides_default_args!) {
        updated: upsert_pricing_overrides_default(args: $override) {
            ...PricingOverrideFields
        }
    }
`;

export const UPSERT_DRIVER_TARIFFS = gql`
    ${DRIVER_TARIFF_FIELDS}

    mutation UpsertDriverTariffs($mappings: [driver_tariff_mappings_insert_input!]!) {
        updated: insert_driver_tariff_mappings(
            objects: $mappings
            on_conflict: { constraint: driver_tariff_mappings_pkey, update_columns: [tariff_id] }
        ) {
            returning {
                ...DriverTariffFields
            }
        }
    }
`;

export const DUPLICATE_PRICING_TARIFF = gql`
    ${PRICING_TARIFF_FIELDS}

    mutation DuplicatePricingTariff($tariff_id: uuid!) {
        duplicated: duplicate_pricing_tariff(args: { target_id: $tariff_id }) {
            ...PricingTariffFields
        }
    }
`;

export const LIST_TARIFFS_BY_CLIENT_ID = gql`
    ${PRICING_TARIFF_FIELDS}
    ${PRICING_OVERRIDE_FIELDS}
    ${RATE_FIELDS}
    ${DRIVER_TARIFF_FIELDS}

    query ListTariffsByClientId($client_id: uuid!) {
        tariffs: pricing_tariffs(where: { client_id: { _eq: $client_id } }, order_by: { created_at: desc }) {
            ...PricingTariffFields

            rates {
                ...RateFields
            }
        }

        overrides: pricing_overrides(where: { _and: [{ oms: { _eq: true } }, { client_id: { _eq: $client_id } }] }) {
            ...PricingOverrideFields
        }

        driver_tariffs: driver_tariff_mappings(where: { client_id: { _eq: $client_id } }) {
            ...DriverTariffFields
        }
    }
`;

export const GET_TARIFFS_BY_TARIFF_ID = gql`
    ${PRICING_TARIFF_FIELDS}
    ${RATE_FIELDS}

    query GetTariffsById($tariff_id: uuid!) {
        result: pricing_tariffs_by_pk(tariff_id: $tariff_id) {
            ...PricingTariffFields

            rates {
                ...RateFields
            }

            requirements {
                rate_type
            }
        }
    }
`;

export const GET_SUBREGIONS = gql`
    ${SUBREGION_BASE_FIELDS}

    query GetSubregionsByClient($client_id: uuid!) {
        results: subregions(where: { client_id: { _eq: $client_id } }) {
            ...SubregionBaseFields
        }
    }
`;
