import React, { useContext, useMemo, useState } from 'react';
import { Box, FormControl, Select, MenuItem } from '@material-ui/core';
import { css } from '@emotion/react';
import { MODALS, ROUTE_COLORS } from '../constants';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { PlanningContext } from '../context';
import { useMutation } from '@apollo/client';
import { ASSIGN_ORDERS, UPDATE_ROUTE_STOPS } from '../graphql/mutations';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import routeStatusOptions from '@/constants/routeStatusOptions';
import { useClientUser } from '@/hooks/useClientUser';
import { colors } from '@/styles';
import { Body1, GridItemRow, SecondaryButtonFullW } from '../blocks';
import SendToOnward from './SendToOnward';
import { cloneDeep } from 'lodash';
import { LTL_MARKETPLACE, PLAN_PAGE_LOADBOARD } from '@/constants/featureFlags';
import { toArrayLiteral } from '@/utilities/toArrayLiteral';
import { PrimaryButton, SecondaryButton } from '@/styles/blocks';
import { removeRefs } from '@/graphql/util';

const AssignToRoute = () => {
    const {
        selectedOrders,
        setSelectedOrders,
        setNotification,
        setError,
        setModalOpen,
        state: { orders, routes, loading },
        callbacks: { refetch },
    } = useContext(PlanningContext);
    const [assignedRoute, setAssignedRoute] = useState('');
    const { circles, default_end_location, user_id } = useClientUser();

    const routesByKey = useMemo(() => {
        return Object.fromEntries(routes.map((route) => [route.route_id, route]));
    }, [routes]);

    const ordersByKey = useMemo(() => {
        return Object.fromEntries(orders.map((order) => [`${order.order_id}_${order.crossdock_leg}`, order]));
    }, [orders]);

    const ordersToAdd = useMemo(() => {
        return Object.entries(selectedOrders)
            .filter(([_, selected]) => selected)
            .map(([key]) => ordersByKey[key])
            .filter(
                (order) =>
                    order && !(order.pickup_route_id === assignedRoute || order.dropoff_route_id === assignedRoute)
            );
    }, [selectedOrders, ordersByKey, assignedRoute]);

    const [assignOrders, { loading: assignLoading }] = useMutation(ASSIGN_ORDERS);

    const [updateStops] = useMutation(UPDATE_ROUTE_STOPS);

    const handleRemoveOrder = async (order, route) => {
        let newStops = cloneDeep(route?.stopsByRouteId || []);
        newStops = await FTLStopHelpers.removeStop(order, { ...route, stopsByRouteId: newStops }, default_end_location);

        const isDelivery = route?.orders?.find((o) => o.order_id === order.order_id)?.type !== 'PICKUP';

        let updatedOrder;
        await updateStops({
            variables: {
                route_id: route?.route_id,
                route_update: {
                    need_to_optimize: true,
                },
                events: [
                    isDelivery
                        ? {
                              order_id: order.order_id,
                              action: `${order.event_state}:${
                                  order.event_state === 'PICKED_UP' ? 'REMOVE' : 'REMOVE_DO'
                              }`,
                              notes: `Removed route ${route.route_number}`,
                          }
                        : {
                              order_id: order.order_id,
                              action: `${order.event_state}:REMOVE_PU`,
                              notes: `Removed route ${route.route_number}`,
                          },
                ],
                order_updates: isDelivery
                    ? [
                          {
                              where: { order_id: { _eq: order.order_id } },
                              _set: {
                                  delivery_time_confirmed: null,
                                  del_window_start: null,
                                  del_window_end: null,
                                  original_del_window_start: null,
                                  original_del_window_end: null,
                                  order_status:
                                      order.shipper_id !== order.carrier_id && order.carrier_id === user_id
                                          ? 'claimed'
                                          : 'pending',
                              },
                          },
                      ]
                    : [],
                ...FTLStopHelpers.gqlStopUpdates(newStops, route),
            },
            onError: (error) => {
                setError(error, `Failed to remove order(s) from route ${route?.route_number}`);
            },
            update: (cache, { data }) => {
                const removedStops = data?.delete_stops?.returning || [];
                removedStops.forEach((stop) => cache.evict(cache.identify(stop)));
                cache.modify({
                    id: cache.identify(route),
                    fields: {
                        stopsByRouteId: (prev, { toReference }) => {
                            return removeRefs(prev, removedStops, { toReference });
                        },
                    },
                });
            },
            onCompleted: (data) => {
                updatedOrder = data.update_orders_many[0]?.returning
                    ? {
                          ...data.update_orders_many[0].returning[0],
                          ...(order.event_state === 'ROUTED_DO' || order.event_state === 'CD_PENDING'
                              ? { crossdock_leg: 'dropoff' }
                              : {}),
                      }
                    : {
                          ...order,
                          wh_events: [...order.wh_events, ...data.insert_order_wh_events.returning],
                          event_state: order.event_state === 'CD_PENDING' ? 'ROUTED_DO' : 'CD_PENDING_PO',
                      };
            },
        });
        return updatedOrder;
    };

    const assign = async () => {
        const assignedRouteObj = routesByKey[assignedRoute];

        let _orders = [...ordersToAdd];

        const submittedOrders = _orders
            .filter((order) => {
                const routeId = order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id;
                return !!routesByKey[routeId];
            })
            .map((order) => ({
                order,
                route: routesByKey[order.crossdock_leg === 'pickup' ? order.pickup_route_id : order.dropoff_route_id],
            }));

        if (submittedOrders.length) {
            if (submittedOrders.some((orderRoute) => orderRoute.route?.status !== routeStatusOptions.PLANNING.value)) {
                setNotification({
                    severity: 'warning',
                    message:
                        'Cannot move orders from or to a locked route. If you would like to make edits, unlock the route(s) in question. Then reoptimize and resubmit the route once done editing.',
                });
                return;
            } else {
                for (const orderRoute of submittedOrders) {
                    const updatedOrder = await handleRemoveOrder(orderRoute.order, orderRoute.route);
                    const index = _orders.findIndex((order) => order.order_id === updatedOrder.order_id);
                    if (index !== -1) {
                        _orders[index] = updatedOrder;
                    }
                }
            }
        }

        // Both legs assigned to same route
        const dupeOrders = _orders.reduce((acc, order) => {
            return {
                ...acc,
                [order.order_id]: [...(acc[order.order_id] || []), order],
            };
        }, {});
        const alreadyAssigned = _orders.filter((order) =>
            assignedRouteObj?.orders?.some((mapping) => mapping.order_id === order.order_id)
        );
        if (alreadyAssigned.length > 0 || Object.values(dupeOrders).some((x) => x.length > 1)) {
            setNotification({
                severity: 'warning',
                message:
                    'Cannot assign both (pickup -> cross-dock) and (cross-dock -> delivery) legs of an order to the same route.',
            });
            return;
        }

        // Invariant to ensure a locked route is not assigned to.
        if (assignedRouteObj?.status !== routeStatusOptions.PLANNING.value) {
            setNotification({
                severity: 'warning',
                message:
                    'Cannot assign to a locked route. If you would like to make edits, unlock the route if necessary, make the desired addition, and reoptimize the route.',
            });
            return;
        }

        try {
            const newStops = FTLStopHelpers.addOrders(assignedRouteObj, _orders);

            const addActions = {
                pickup: 'ADD_PU',
                dropoff: 'ADD_DO',
            };

            const events = _orders.map((order) => ({
                order_id: order.order_id,
                action: `${order.event_state}:${addActions[order.crossdock_leg] || 'PICKING_UP'}`,
                route_id: assignedRoute,
                notes: `Added to route ${assignedRouteObj.route_number} (${assignedRouteObj.route_alias})`,
                ...(order.crossdock_leg !== 'pickup'
                    ? {
                          est_delivery_date: assignedRouteObj.scheduled_delivery,
                      }
                    : {}),
                ...(order.crossdock_leg !== 'dropoff'
                    ? {
                          est_pickup_date: assignedRouteObj.scheduled_delivery,
                      }
                    : {}),
            }));

            const optimisticRoute = {
                ...assignedRouteObj,
                orders: [
                    ...(assignedRouteObj.orders || []),
                    ..._orders.map((o) => ({
                        order_id: o.order_id,
                        type:
                            o.crossdock_leg === 'pickup'
                                ? 'PICKUP'
                                : o.crossdock_leg === 'dropoff'
                                ? 'DROPOFF'
                                : 'FULL',
                        order: o,
                    })),
                ],
            };

            const order_updates = _orders.map((order) => {
                let customerOrderUpdate = {
                    del_window_start: null,
                    del_window_end: null,
                    original_del_window_start: null,
                    original_del_window_end: null,
                    delivery_time_confirmed: null,
                };
                const matchingStop = newStops.find(
                    (s) =>
                        (s.orders || []).includes(order.order_id) && FTLStopHelpers.isCustomerStop(s, optimisticRoute)
                );
                if (matchingStop && matchingStop.del_window_start) {
                    customerOrderUpdate = {
                        del_window_start: matchingStop.del_window_start,
                        del_window_end: matchingStop.del_window_end,
                        original_del_window_start: matchingStop.del_window_start,
                        original_del_window_end: matchingStop.del_window_end,
                    };
                }

                const isCustomerLeg =
                    (order.order_type === 'return' && order.crossdock_leg !== 'dropoff') ||
                    (order.order_type !== 'return' && order.crossdock_leg !== 'pickup');

                return {
                    where: { order_id: { _eq: order.order_id } },
                    _set: {
                        ...(order.crossdock_leg === 'pickup'
                            ? {
                                  pickup_date: assignedRouteObj.scheduled_delivery,
                              }
                            : {
                                  delivery_date: assignedRouteObj.scheduled_delivery,
                              }),
                        ...(isCustomerLeg ? customerOrderUpdate : {}),
                    },
                };
            });

            console.debug(newStops, events, order_updates);

            assignOrders({
                variables: {
                    route_id: assignedRoute,
                    order_updates,
                    stops: newStops.map(({ __typename, ...stop }) => ({
                        ...stop,
                        route_id: assignedRoute,
                        ...Object.fromEntries(
                            ['orders', 'returns', 'exchanges', 'actions_completed'].map((attr) => [
                                attr,
                                toArrayLiteral(stop[attr]),
                            ])
                        ),
                    })),
                    events,
                },
                onError: (error) => {
                    setError(error, 'Error assigning orders to route');
                },
                onCompleted: () => {
                    refetch();
                    setSelectedOrders({});
                    setAssignedRoute('');
                },
            });
        } catch (error) {
            setError(error, error.message || 'Error assigning order to route');
        }
    };

    const assignedRouteObject = routesByKey[assignedRoute];

    const isLoading = loading || assignLoading;

    return (
        <Box
            css={css`
                background-color: white;
                width: 300px;
                position: fixed;
                right: 16px;
                bottom: 16px;
                border: 1px solid rgba(0, 0, 0, 0.32);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px 5px 0px 0px;
            `}
        >
            <GridItemRow
                css={css`
                    border-bottom: 1px solid #e2e2e2;
                    padding: 0.875rem 1rem 0.875rem 1rem;
                `}
            >
                <Body1
                    css={css`
                        color: ${colors.greys.primary};
                    `}
                >
                    Assign to
                </Body1>
                <Body1>{Object.entries(selectedOrders).filter(([, toggle]) => toggle)?.length} orders selected</Body1>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.875rem 1rem 0.375rem 1rem;
                    fieldset {
                        top: 0;
                    }
                `}
            >
                <FormControl fullWidth variant="outlined">
                    <Select
                        value={assignedRoute || 'NONE'}
                        onChange={(e) => setAssignedRoute(e.target.value)}
                        disabled={!routes.length}
                    >
                        <MenuItem value="NONE" key="REMOVE">
                            Select a Route
                        </MenuItem>
                        {routes.map((route, i) => (
                            <MenuItem value={route.route_id} key={`route-menu-item-${route.route_id}`}>
                                <LocalShippingOutlinedIcon
                                    fontSize="small"
                                    css={css`
                                        color: ${route.route_color || ROUTE_COLORS[i] || 'black'};
                                        margin-right: 5px;
                                    `}
                                />
                                {route.route_alias || `Route ${route.route_number}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.375rem 1rem 0.875rem 1rem;
                `}
            >
                <PrimaryButton fullWidth disabled={isLoading || !assignedRouteObject} onClick={assign}>
                    {assignedRouteObject && assignedRouteObject.status !== 'planning'
                        ? 'Route is Locked'
                        : `Assign to Route`}
                </PrimaryButton>
            </GridItemRow>
            <GridItemRow
                css={css`
                    padding: 0.375rem 1rem 0.875rem 1rem;
                `}
            >
                <PrimaryButton fullWidth disabled={isLoading} onClick={() => setModalOpen(MODALS.ORDER_RESCHEDULE)}>
                    Reschedule Orders
                </PrimaryButton>
            </GridItemRow>
            {circles?.[LTL_MARKETPLACE] ? <SendToOnward orders={ordersToAdd} /> : null}
        </Box>
    );
};

export default AssignToRoute;
