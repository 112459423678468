import { Grid, Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useClientUser } from '../../hooks';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dateFnsFormat from 'date-fns/format';
import StoreOutlinedIcon from '@material-ui/icons/StoreOutlined';
import WhereToVoteOutlinedIcon from '@material-ui/icons/WhereToVoteOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

import DelayTimeModal from './DelayTimeModal';
import CompleteStopModal from './CompleteStopModal';
import CancellationModal from '../../components/CancellationModal';
import StopDetailsModal from './StopDetailsModal';
import Snackbar from '../../components/Snackbar';
import getDeliveryWindow from '../../utilities/getDeliveryWindow';
import getDeliveryWindowDelayed from '../../utilities/getDeliveryWindowDelayed';
import { calcDefaultServiceTime } from '@/utilities/calculateTimeframes';
import { colors } from '@/styles';
import { H2, Body1, GreyBody, Body2, RedBody, local, fragments } from './blocks';
import { isOrderCancelled } from './isOrderCancelled';
import StopCardMenu from './StopCardMenu';
import StopNotes from './StopNotes';
import deliveryStrings from '@/constants/deliveryStrings';
import FTLStopHelpers from '@/utilities/FTLStopHelpers';
import ExceptionCard from '../OrderDetailsPage/ExceptionCard';
import { PrimaryButton } from '@/styles/blocks';
import { useTotalRevenue } from '@/hooks';

const minuteString = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const StopCard = ({
    stopNumber,
    stop,
    orders,
    route,
    callbacks,
    rescheduledStops,
    cancelledStops,
    stopOrderMappings,
}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [stopSaving, setStopSaving] = useState(false);
    const { accountType, originalAdminEmail, roles, teammateRoles, user_id, ...timeframeOptions } = useClientUser();

    const [delayTimeframeModalOpened, setDelayTimeframeModalOpened] = useState(false);
    const [cancelModalOpened, setCancelModalOpened] = useState(false);
    const [stopDetailsModal, setStopDetailsModal] = useState(false);
    const [completeStopModalOpened, setCompleteStopModalOpened] = useState(false);

    const finishReturnsOn = useMemo(() => {
        const totalStops = route.stopsByRouteId.length;

        if (route.status === 'complete' || stopNumber !== totalStops || route.finishReturnsNextDay === undefined) {
            return;
        } else {
            return route.finishReturnsNextDay ? 'Tomorrow' : 'Today';
        }
    }, [route, stopNumber]);

    const mappingsByOrderKey = useMemo(() => {
        return Object.fromEntries((route.orders || []).map((mapping) => [mapping.order_id, mapping]));
    }, [route]);

    const getTotalCubes = (itemType) => {
        if (orders?.length && itemType === 'normal') {
            return orders.reduce((totalCubes, order) => {
                let cubes = order.items_total_cubes || order.total_cubes || 0;
                if (!cubes && order?.itemsByOrderId?.length) {
                    cubes = order.itemsByOrderId.reduce(
                        (prevCubes, item) => prevCubes + parseFloat(item.total_cubes),
                        0
                    );
                }
                return parseInt(totalCubes + parseFloat(cubes));
            }, 0);
        } else if (orders?.length && itemType === 'return') {
            return orders.reduce((totalCubes, order) => {
                let cubes = order.returns_total_cubes || 0;
                if (!cubes && order?.returns?.length) {
                    cubes = order.returns.reduce((prevCubes, item) => prevCubes + parseFloat(item.totalCubes), 0);
                }
                return parseInt(totalCubes + parseFloat(cubes));
            }, 0);
        }
        return 0;
    };

    const getTotalItems = (itemType) => {
        if (orders?.length && itemType === 'normal') {
            return orders.reduce((totalItems, order) => {
                let itemsQty = order.items_total_quantity || 0;
                if (!itemsQty && order?.itemsByOrderId?.length) {
                    itemsQty = order.itemsByOrderId.reduce((prevQty, item) => prevQty + parseFloat(item.quantity), 0);
                }
                return totalItems + parseFloat(itemsQty);
            }, 0);
        } else if (orders?.length && itemType === 'return') {
            return orders.reduce((totalItems, order) => {
                let itemsQty = order.returns_total_quantity || 0;
                if (!itemsQty && order?.returns?.length) {
                    itemsQty = order.returns.reduce((prevQty, item) => prevQty + parseFloat(item.quantity), 0);
                }
                return totalItems + parseFloat(itemsQty);
            }, 0);
        }
        return 0;
    };

    const getTotalWeight = (itemType) => {
        if (orders && orders.length && itemType === 'normal') {
            return orders.reduce((totalWeight, order) => {
                let weight = order.items_total_weight || order.total_weight || 0;
                if (!weight && order?.itemsByOrderId?.length) {
                    weight = order.itemsByOrderId.reduce(
                        (prevWeight, item) => prevWeight + parseFloat(item.total_weight),
                        0
                    );
                }
                return parseInt(totalWeight + parseFloat(weight));
            }, 0);
        } else if (orders && orders.length && itemType === 'return') {
            return orders.reduce((totalWeight, order) => {
                let weight = order.returns_total_weight || 0;
                if (!weight && order?.returns?.length) {
                    weight = order.returns.reduce((prevWeight, item) => prevWeight + parseFloat(item.totalWeight), 0);
                }
                return parseInt(totalWeight + parseFloat(weight));
            }, 0);
        }
        return 0;
    };

    const canViewRevenue = !roles?.TEAMMATE || (roles?.TEAMMATE && !teammateRoles?.driver);
    const totalRevenue = canViewRevenue ? useTotalRevenue(orders) : 0;

    const getdropofftype = () => {
        if (orders && orders.length) {
            let dropoffArray = [];
            const _location = orders.find((order) => !!order.dropoff_location)?.dropoff_location;
            if (_location) dropoffArray.push(_location);
            const _dropofftype = orders.find((order) => !!order.dropoff_location_type)?.dropoff_location_type;
            if (_dropofftype) dropoffArray.push(deliveryStrings.dropOffLocationType[_dropofftype]);

            return `Delivery: ${dropoffArray.join(', ')}`;
        }
    };

    const hasClaimedOrder = useMemo(() => {
        if (orders[0]?.shipper_id !== orders[0]?.carrier_id && orders[0]?.carrier_id === user_id) {
            return true;
        }
        return false;
    }, [orders]);

    const ordersByKey = useMemo(() => {
        if (!orders) {
            return {};
        }

        return Object.fromEntries(orders.map((order) => [order.order_id, order]));
    }, [orders]);

    const serviceTime = useMemo(() => {
        if (!stop) {
            return 0;
        }

        if (stop.service_time === 0 || stop.service_time) {
            return stop.service_time / 1000 / 60;
        }

        const { hours, minutes } =
            Object.values(ordersByKey).length > 0 ? calcDefaultServiceTime(stop, ordersByKey, timeframeOptions) : {};
        return (hours || 0) * 60 + (minutes || 0);
    }, [stop, ordersByKey]);

    const getHaulaway = () => {
        if (orders && orders.length) {
            const totalHaulaways = orders.reduce((haulaways, order) => {
                //temporary solution until we figure out why some orders have empty haulawayItems with qty of 1
                return (
                    haulaways +
                    (order?.haulaway_items?.reduce(
                        (quantity, item) => (item.description ? quantity + item.quantity || 1 : 0),
                        0
                    ) || 0)
                );
            }, 0);
            return totalHaulaways ? `Haul-away: ${totalHaulaways}` : '';
        }
        return '';
    };

    let cancelMap = {};
    const allCanceled = useMemo(() => {
        const cancelledStop = cancelledStops.find(({ stop: cancelledStop }) => cancelledStop.stop_id === stop.stop_id);
        return cancelledStop ? cancelledStop.cancelled : false;
    }, [cancelledStops, stop.stop_id]);

    const isRescheduled = (order) => {
        return order.exceptions.some(
            (exception) =>
                exception.exception.resolution === 'RESCHEDULE' && exception.exception.route_id === route.route_id
        );
    };

    let rescheduleMap = {};
    const allRescheduled = useMemo(() => {
        const rescheduledStop = rescheduledStops.find(
            ({ stop: rescheduledStop }) => rescheduledStop.stop_id === stop.stop_id
        );
        return rescheduledStop ? rescheduledStop.rescheduled : false;
    }, [rescheduledStops, stop.stop_id]);

    const exceptionList = useMemo(() => {
        const exceptions = new Set();
        orders?.forEach((order) => {
            if (order.order_exception) {
                exceptions.add(order.order_exception.type);
            }
            order.itemsByOrderId?.forEach((item) => {
                if (item.item_exception) {
                    exceptions.add(item.item_exception.type);
                }
            });
        });
        return Array.from(exceptions);
    }, [orders]);

    const hasExceptions = useMemo(() => {
        return orders?.some((order) => {
            return order.exceptions.some((mapping) => {
                return mapping.exception.status !== 'RESOLVED' && mapping.exception.reported_at === stop.type;
            });
        });
    }, [orders, stop]);

    const isDelayed = () => Boolean(stop?.del_window_delay);

    const calculateDeliveryDuration = (start, end) => {
        const arrivalTime = new Date(start).getTime();
        const completionTime = new Date(end).getTime();
        const stopDuration = completionTime - arrivalTime;

        let durationSeconds = stopDuration / 1000;
        const durationHours = parseInt(durationSeconds / 3600);
        durationSeconds = durationSeconds % 3600;
        const durationMinutes = parseInt(durationSeconds / 60);
        durationSeconds = durationSeconds % 60;
        durationSeconds = durationSeconds.toFixed(0);

        return `${durationHours} h ${durationMinutes} min ${durationSeconds} sec`;
    };

    const isFinalReturnStop = (stop) => {
        if (stop.type === 'DROPOFF' && stop.returns?.length) {
            return true;
        }
        return false;
    };

    const Card = styled((props) => <Grid {...props} />)`
        ${fragments.card}
        padding: 16px;

        ${stop.nextStop &&
        css`
            border: 2px solid #000000;
            box-shadow: 0px 1px 8px 3px rgba(63, 63, 68, 0.2);
            border-radius: 4px;
        `}

        ${(allCanceled || allRescheduled) &&
        css`
            color: ${colors.greys.primary};
        `}
    `;

    const ordersCancelledCallback = (ordersCancelled, error) => {
        if (error) {
            setErrorMessage(
                'It appears something has gone wrong, please try again. If the problem persists then please contact support.'
            );
        } else {
            setSuccessMessage(
                `Order(s) (${ordersCancelled
                    .map(({ order_number }) => order_number)
                    .join(', ')}) successfully cancelled`
            );
        }
    };

    const isReturnPickupStop = (stop) => stop.type === 'PICKUP' && stop?.returns?.length;

    const stopLabel = useMemo(() => {
        if (stop.type === 'LUNCH') return `${stop.ordering}. Lunch`;

        let name = 'Stop';
        if (FTLStopHelpers.isCustomerStop(stop, route)) {
            name = orders[0]?.dropoff_name || 'Customer Name';
        } else if (FTLStopHelpers.isCrossdockStop(stop, route)) {
            name = 'Cross-dock';
        } else if (FTLStopHelpers.isFinalDropoff(stop)) {
            name = 'Final Return';
        } else if (stop.type === 'PICKUP') {
            name = 'Pickup';
        } else if (stop.type === 'DROPOFF') {
            name = 'Dropoff';
        }
        return `${stop.ordering}. ${name}`;
    }, [route, stop, orders]);

    const stopTag = useMemo(() => {
        const iconFrag = css`
            font-size: 20px;
            color: ${colors.greys.primary};
        `;
        const bodyFrag = css`
            color: ${colors.greys.primary};
        `;
        if (FTLStopHelpers.isCustomerStop(stop, route)) {
            return (
                <>
                    <HomeIcon css={iconFrag} />
                    <Body1 css={bodyFrag}>{stop.type === 'PICKUP' ? 'Return Pickup' : 'Delivery'}</Body1>
                </>
            );
        }
        if (FTLStopHelpers.isCrossdockStop(stop, route)) {
            return (
                <>
                    <WarehouseOutlinedIcon css={iconFrag} />
                    <Body1 css={bodyFrag}>Cross-dock</Body1>
                </>
            );
        }
        return (
            <>
                <StoreOutlinedIcon css={iconFrag} />
                <Body1 css={bodyFrag}>
                    {FTLStopHelpers.isFinalDropoff(stop)
                        ? 'Final Return'
                        : stop.type === 'PICKUP'
                        ? 'Pickup'
                        : 'Dropoff'}
                </Body1>
            </>
        );
    }, [route, stop]);

    const isCustomerStop = useMemo(() => {
        return FTLStopHelpers.isCustomerStop(stop, route);
    }, [route, stop]);

    return (
        <Card container>
            <DelayTimeModal
                open={delayTimeframeModalOpened}
                onClose={() => setDelayTimeframeModalOpened(false)}
                setErrorMessage={setErrorMessage}
                route={route}
                stop={stop}
                setStopSaving={setStopSaving}
            />
            <CancellationModal
                open={cancelModalOpened}
                onClose={() => setCancelModalOpened(false)}
                orders={orders.filter((o) => !isOrderCancelled(o))}
                ordersCancelledCallback={ordersCancelledCallback}
                route={route}
            />
            <CompleteStopModal
                open={completeStopModalOpened}
                onClose={() => setCompleteStopModalOpened(false)}
                stop={stop}
                rescheduledStops={rescheduledStops}
                cancelledStops={cancelledStops}
                stopOrderMappings={stopOrderMappings}
                route={route}
                setErrorMessage={setErrorMessage}
                setStopSaving={setStopSaving}
            />
            {stopDetailsModal && (
                <StopDetailsModal
                    open={stopDetailsModal}
                    stop={stop}
                    orders={orders}
                    title={stopLabel}
                    onClose={() => setStopDetailsModal(false)}
                />
            )}
            <Grid container direction="column">
                <Grid container>
                    <Grid item xs={6}>
                        <H2>{stopLabel}</H2>
                        {stop.nextStop && (
                            <Body2
                                css={css`
                                    margin-left: 1rem;
                                    color: ${colors.greens.primary};
                                `}
                            >
                                Next Stop
                            </Body2>
                        )}
                    </Grid>

                    <Grid container alignItems="center" justifyContent="flex-end" xs={6}>
                        {!!stop.stop_completion_time && !allCanceled && !hasExceptions && (
                            <CheckCircleIcon
                                css={css`
                                    font-size: 20px;
                                    color: ${colors.greens.primary};
                                `}
                            />
                        )}
                        {hasExceptions && (
                            <CheckCircleIcon
                                css={css`
                                    font-size: 20px;
                                    color: ${colors.oranges.primary};
                                `}
                            />
                        )}

                        {stopTag}

                        <StopCardMenu
                            stop={stop}
                            routeStatus={route.status}
                            callbacks={{
                                setCancelModalOpened,
                                setDelayTimeframeModalOpened,
                                setStopDetailsModal,
                                setCompleteStopModalOpened,
                            }}
                        />
                    </Grid>
                </Grid>

                {(stop.type === 'DROPOFF' || isReturnPickupStop(stop)) && !isFinalReturnStop(stop) && (
                    <Grid container>
                        <Body1>Customer: {orders[0]?.dropoff_name || ''}</Body1>
                    </Grid>
                )}

                <Grid container>
                    <Body1>{stop.address}</Body1>
                </Grid>

                {(stop.type !== 'PICKUP' || !stop.returns) && (
                    <Grid container>
                        <Body1>
                            {getTotalItems('normal')} Items / {getTotalCubes('normal')} cu ft. /{' '}
                            {getTotalWeight('normal')} lbs
                        </Body1>
                    </Grid>
                )}

                {totalRevenue > 0 && (
                    <Grid container>
                        <Body1>Revenue: ${totalRevenue}</Body1>
                    </Grid>
                )}

                {(stop.exchanges || stop.returns) && (
                    <Grid container>
                        <Body1>
                            {getTotalItems('return')} Return Items / {getTotalCubes('return')} cu ft. /{' '}
                            {getTotalWeight('return')} lbs
                        </Body1>
                    </Grid>
                )}

                {stop.type === 'DROPOFF' && (
                    <Grid container>
                        <GreyBody>{getdropofftype()}</GreyBody>
                    </Grid>
                )}

                {stop.returns && (
                    <Grid container>
                        <GreyBody>Return</GreyBody>
                    </Grid>
                )}

                {stop.exchanges && (
                    <Grid container>
                        <GreyBody>Exchange</GreyBody>
                    </Grid>
                )}

                <Grid container>
                    <Body1
                        css={css`
                            color: ${local.grey};
                        `}
                    >
                        {`${
                            stop.type === 'DROPOFF' || isReturnPickupStop(stop) ? 'Service Time' : 'Load Time'
                        }: ${minuteString.format(serviceTime)}`}
                    </Body1>
                </Grid>

                {stop.type === 'PICKUP' && !isReturnPickupStop(stop) && (
                    <Grid container>
                        <Body1>{`${isDelayed() ? 'Delayed ' : ''}Time Frame: ${getDeliveryWindowDelayed(
                            stop,
                            route.scheduled_delivery
                        )}`}</Body1>
                    </Grid>
                )}

                {(stop.type === 'DROPOFF' || isReturnPickupStop(stop)) && (
                    <>
                        {isDelayed() && (
                            <Body1
                                css={css`
                                    color: ${colors.golds.primary};
                                `}
                            >
                                Delayed Time Frame: {getDeliveryWindowDelayed(stop, route.scheduled_delivery)}
                            </Body1>
                        )}
                        <Grid container>
                            <Grid item xs={originalAdminEmail || roles?.ADMIN ? 6 : 12}>
                                <Body1>
                                    {`${isDelayed() ? 'Prior ' : ''}Time Frame: ${getDeliveryWindow(
                                        stop,
                                        route.scheduled_delivery
                                    )}`}
                                </Body1>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid container alignItems="center" justifyContent="flex-start" xs={5}>
                                <Body1>{getHaulaway()}</Body1>
                            </Grid>
                        </Grid>
                    </>
                )}

                {allCanceled && (
                    <Body2
                        css={css`
                            color: ${local.red};
                        `}
                    >
                        Cancelled
                    </Body2>
                )}

                <Grid container>
                    {hasClaimedOrder && stop.type !== 'PICKUP' && (
                        <Grid item xs={12}>
                            <Body1
                                css={css`
                                    color: ${colors.greens.primary};
                                `}
                            >
                                Claimed Order
                            </Body1>
                        </Grid>
                    )}
                    {stop.arrival_time && (
                        <Grid item xs={12}>
                            <Body2>Arrived: {dateFnsFormat(new Date(stop.arrival_time), 'PPPP @ p')}</Body2>
                        </Grid>
                    )}
                    {stop.geo_fence_enter && (
                        <Grid item xs={12}>
                            <Body2>Geofence Enter: {dateFnsFormat(new Date(stop.geo_fence_enter), 'PPPP @ p')}</Body2>
                        </Grid>
                    )}
                    {stop.stop_completion_time && (
                        <Grid item xs={12}>
                            <Body2>Complete: {dateFnsFormat(new Date(stop.stop_completion_time), 'PPPP @ p')}</Body2>
                        </Grid>
                    )}
                    {stop.geo_fence_exit && (
                        <Grid item xs={12}>
                            <Body2>Geofence Exit: {dateFnsFormat(new Date(stop.geo_fence_exit), 'PPPP @ p')}</Body2>
                        </Grid>
                    )}
                    {stop.arrival_time && stop.stop_completion_time && (
                        <Grid item xs={12}>
                            <Body2>
                                Delivery Duration:{' '}
                                {calculateDeliveryDuration(stop.arrival_time, stop.stop_completion_time)}
                            </Body2>
                        </Grid>
                    )}
                    {stop.geo_fence_enter && stop.geo_fence_exit && (
                        <Grid item xs={12}>
                            <Body2>
                                Geofence Duration:{' '}
                                {calculateDeliveryDuration(stop.geo_fence_enter, stop.geo_fence_exit)}
                            </Body2>
                        </Grid>
                    )}
                    {finishReturnsOn && (
                        <Grid item xs={12}>
                            <Body2>{`Returns: ${finishReturnsOn}`}</Body2>
                        </Grid>
                    )}
                </Grid>

                <Grid container direction="column" key={stop.ordering}>
                    {orders.map((order, index) => (
                        <Grid container direction="column" key={`${order.order_id}-${stop.ordering}`}>
                            <Grid item>
                                <Body2>
                                    {order.order_number} / {order.po_number} {isRescheduled(order) && '(Rescheduled)'}
                                </Body2>
                            </Grid>
                            {!FTLStopHelpers.isFinalDropoff(stop)
                                ? (order.exceptions || [])
                                      .filter(
                                          (mapping) =>
                                              mapping.exception_type === 'ORDER' &&
                                              (mapping.exception?.route_id === route.route_id ||
                                                  !mapping.exception?.route_id) &&
                                              //   mapping.exception.status !== 'RESOLVED' &&
                                              mapping.exception.reported_at === stop.type
                                      )
                                      .map((mapping) => (
                                          <Grid key={`${mapping.exception_id}-${stop.ordering}`} item>
                                              <ExceptionCard
                                                  exception={mapping.exception}
                                                  order={order}
                                                  callbacks={{
                                                      setExceptionParams: () =>
                                                          callbacks.editException({
                                                              type: stop.type,
                                                              prev: mapping.exception,
                                                              order,
                                                          }),
                                                      setOrderId: callbacks.setOrderId,
                                                      setResolveExceptionParams: callbacks.setResolveExceptionParams,
                                                  }}
                                              />
                                          </Grid>
                                      ))
                                : null}
                            {stop.type === 'PICKUP' &&
                            ['PICKED_UP', 'ROUTED_PU', 'CD_PENDING'].includes(order.event_state) ? (
                                <Grid
                                    item
                                    css={css`
                                        margin-top: ${order.exceptions && order.exceptions.length > 0 ? '5px' : '3px'};
                                    `}
                                >
                                    <PrimaryButton
                                        orange
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            callbacks.editException({
                                                type: stop.type,
                                                order,
                                                action: `${order.event_state}:ATTEMPT`,
                                                orderUpdates: {
                                                    ...(order.event_state === 'PICKED_UP'
                                                        ? {
                                                              delivery_date: null,
                                                              order_status: 'pending',
                                                          }
                                                        : {
                                                              pickup_date: null,
                                                              order_status: 'pending',
                                                          }),
                                                },
                                            });
                                        }}
                                    >
                                        Reschedule Pickup
                                    </PrimaryButton>
                                </Grid>
                            ) : null}
                            {stop.type === 'DROPOFF' &&
                            !stop?.returns &&
                            ['CD_COMPLETE'].includes(order.event_state) ? (
                                <Grid item>
                                    <PrimaryButton
                                        orange
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            callbacks.editException({
                                                type: stop.type,
                                                order,
                                                action: `${order.event_state}:${
                                                    mappingsByOrderKey[order.order_id]?.type === 'DROPOFF'
                                                        ? 'DO_ATTEMPT'
                                                        : 'ATTEMPT'
                                                }`,
                                                orderUpdates: {
                                                    delivery_date: null,
                                                    order_status: 'pending',
                                                },
                                            });
                                        }}
                                    >
                                        Reschedule Dropoff
                                    </PrimaryButton>
                                </Grid>
                            ) : null}
                        </Grid>
                    ))}
                </Grid>

                {/* {!(stop.type === 'PICKUP' && !stop.returns) && <StopNotes stop={stop} route={route} orders={orders} />} */}
            </Grid>

            <Snackbar
                open={!!successMessage}
                severity="success"
                message={successMessage}
                handleClose={() => window.location.reload()}
                autoHideDuration={5000}
            />

            <Snackbar
                open={!!errorMessage}
                severity="error"
                message={errorMessage}
                handleClose={() => setErrorMessage('')}
            />
        </Card>
    );
};

export default StopCard;
