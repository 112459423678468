import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { LocationOn } from '@material-ui/icons';
import { BodyCellText } from '../MyOrders/blocks';
import { PALLET_STATUS_LABELS } from '@/constants/manifestStatuses';

export const useTableColumns = ({ isPalletView }) => {
    return useMemo(() => {
        return [
            {
                Header: 'Inventory Management',
                id: 'inventory-details-group',
                columns: [
                    {
                        Header: 'SKU',
                        id: 'completed_date',
                        width: 200,
                        disableSortBy: false,
                        accessor: (rowData) => rowData.sku,
                    },
                    ...(isPalletView
                        ? [
                              {
                                  Header: 'Pallet Id',
                                  id: 'pallet_name',
                                  width: 150,
                                  disableSortBy: false,
                                  accessor: (rowData) =>
                                      rowData.pallets[0]
                                          ? rowData.pallets[0].pallet_name || rowData.pallets[0].pallet_number
                                          : '-',
                              },
                          ]
                        : [
                              {
                                  Header: 'Pallets',
                                  id: 'pallets',
                                  width: 100,
                                  disableSortBy: false,
                                  accessor: (rowData) => rowData.pallets.length,
                              },
                          ]),
                    {
                        Header: 'Order',
                        id: 'order_number',
                        width: 100,
                        disableSortBy: false,
                        accessor: (rowData) => rowData.order_number,
                    },
                    {
                        Header: 'PO',
                        id: 'po_number',
                        width: 155,
                        disableSortBy: false,
                        accessor: (rowData) => (!rowData.po_number ? 'N/A' : rowData.po_number),
                        Cell: ({ value, column }) => (
                            <div
                                css={css`
                                    max-width: ${column.width - 5}px;
                                    overflow-x: auto;
                                    white-space: nowrap;
                                    padding-right: 10px; /* Add padding to ensure text doesn't touch the edge */
                                    margin-right: 10px; /* Add margin to separate from next column */
                                    &::-webkit-scrollbar {
                                        height: 2px; /* For Chrome, Safari, and Opera */
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background-color: rgba(0, 0, 0, 0.2);
                                        border-radius: 2px;
                                    }
                                    scrollbar-width: thin; /* For Firefox */
                                    &:hover {
                                        overflow-x: scroll; /* Show scrollbar on hover */
                                    }
                                `}
                            >
                                <BodyCellText>{value}</BodyCellText>
                            </div>
                        ),
                    },
                    {
                        Header: 'Shipper',
                        id: 'shipper',
                        width: 200,
                        disableSortBy: false,
                        accessor: (rowData) => rowData.shipper,
                        Cell: ({ value, column }) => (
                            <div
                                css={css`
                                    max-width: ${column.width - 5}px;
                                    overflow-x: auto;
                                    white-space: nowrap;
                                    padding-right: 10px; /* Add padding to ensure text doesn't touch the edge */
                                    margin-right: 10px; /* Add margin to separate from next column */
                                    &::-webkit-scrollbar {
                                        height: 2px; /* For Chrome, Safari, and Opera */
                                    }
                                    &::-webkit-scrollbar-thumb {
                                        background-color: rgba(0, 0, 0, 0.2);
                                        border-radius: 2px;
                                    }
                                    scrollbar-width: thin; /* For Firefox */
                                    &:hover {
                                        overflow-x: scroll; /* Show scrollbar on hover */
                                    }
                                `}
                            >
                                <BodyCellText>{value}</BodyCellText>
                            </div>
                        ),
                    },
                    {
                        Header: 'Qty In',
                        id: 'qtyIn',
                        width: 100,
                        disableSortBy: false,
                        accessor: (rowData) => {
                            if (!isPalletView || !rowData.palletId) {
                                return `${rowData.receivedQuantity}`;
                            } else return `${rowData.individualPalletInfo[rowData.palletId].receivedQuantity}`;
                        },
                    },
                    {
                        Header: 'Qty Out',
                        id: 'qtyOut',
                        width: 100,
                        disableSortBy: false,
                        accessor: (rowData) => {
                            if (!isPalletView || !rowData.palletId) {
                                return `${rowData.outQuantity}`;
                            } else return `${rowData.individualPalletInfo[rowData.palletId].outQuantity}`;
                        },
                    },
                    {
                        Header: 'Qty On Hand',
                        id: 'qtyOnHand',
                        width: 120,
                        disableSortBy: false,
                        accessor: (rowData) => {
                            if (!isPalletView || !rowData.palletId) {
                                return `${rowData.receivedQuantity - rowData.outQuantity}`;
                            } else
                                return `${
                                    rowData.individualPalletInfo[rowData.palletId].receivedQuantity -
                                    rowData.individualPalletInfo[rowData.palletId].outQuantity
                                }`;
                        },
                    },
                    {
                        Header: 'Qty Total',
                        id: 'qtyTotal',
                        width: 100,
                        disableSortBy: false,
                        accessor: (rowData) => {
                            if (!isPalletView || !rowData.palletId) {
                                return `${rowData.totalQuantity}`;
                            } else return `${rowData.individualPalletInfo[rowData.palletId].totalQuantity}`;
                        },
                    },
                    {
                        Header: 'Bins/Locations',
                        id: 'locations',
                        width: 175,
                        disableSortBy: false,
                        accessor: (rowData) => {
                            if (!isPalletView) {
                                return rowData.wh_locations.length ? rowData.wh_locations.join(', ') : '-';
                            } else return rowData.pallets[0]?.warehouse_location || '-';
                        },
                    },
                    {
                        Header: 'Date In',
                        id: 'dateIn',
                        width: 150,
                        disableSortBy: false,
                        accessor: (rowData) => rowData.dateIn,
                    },
                    {
                        Header: 'Date Out',
                        id: 'dateOut',
                        width: 150,
                        disableSortBy: false,
                        accessor: (rowData) => rowData.dateOut,
                    },
                    {
                        Header: 'Inbound Manifests',
                        id: 'inManifests',
                        width: 175,
                        disableSortBy: false,
                        accessor: (rowData) =>
                            rowData.inboundManifests.length ? rowData.inboundManifests.join(', ') : '-',
                    },
                    {
                        Header: 'Outbound Manifests',
                        id: 'outManifests',
                        width: 175,
                        disableSortBy: false,
                        accessor: (rowData) =>
                            rowData.outboundManifests.length ? rowData.outboundManifests.join(', ') : '-',
                    },
                ],
            },
            {
                sticky: 'right',
                Header: 'Actions',
                id: 'actions-group',
                columns: [
                    isPalletView
                        ? {
                              Header: 'Pallet Status',
                              id: 'status',
                              width: 150,
                              disableSortBy: false,
                              accessor: (rowData) => {
                                  let fragment;
                                  switch (rowData.pallets[0]?.warehouse_status) {
                                      case 'NOT_RECEIVED':
                                          fragment = css`
                                              color: #d23e3e;
                                          `;
                                          break;
                                      case 'RECEIVED':
                                      case 'STORED':
                                          fragment = css`
                                              color: #007bff;
                                          `;
                                          break;
                                      case 'PICKED':
                                      case 'STAGED':
                                      case 'PICKED_UP':
                                          fragment = css`
                                              color: #59b863;
                                          `;
                                          break;
                                      default:
                                          fragment = css`
                                              color: #d23e3e;
                                          `;
                                  }
                                  return (
                                      <div
                                          css={css`
                                              display: flex;
                                              align-items: center;
                                              white-space: nowrap;
                                          `}
                                      >
                                          <LocationOn
                                              css={css`
                                                  ${fragment}
                                              `}
                                          />
                                          <BodyCellText>
                                              {rowData.pallets.length
                                                  ? PALLET_STATUS_LABELS[rowData.pallets[0]?.warehouse_status]
                                                  : 'Not Received'}
                                          </BodyCellText>
                                      </div>
                                  );
                              },
                          }
                        : {
                              Header: 'SKU Status',
                              id: 'status',
                              width: 150,
                              disableSortBy: false,
                              accessor: (rowData) => {
                                  let fragment;
                                  switch (rowData.status) {
                                      case 'NOT_RECEIVED':
                                          fragment = css`
                                              color: #d23e3e;
                                          `;
                                          break;
                                      case 'IN_STOCK':
                                          fragment = css`
                                              color: #007bff;
                                          `;
                                          break;
                                      case 'COMPLETE':
                                          fragment = css`
                                              color: #59b863;
                                          `;
                                          break;
                                      default:
                                          fragment = css`
                                              color: inherit;
                                          `;
                                  }
                                  return (
                                      <div
                                          css={css`
                                              display: flex;
                                              align-items: center;
                                              white-space: nowrap;
                                          `}
                                      >
                                          <LocationOn
                                              css={css`
                                                  ${fragment}
                                              `}
                                          />
                                          <BodyCellText>
                                              {rowData.status === 'NOT_RECEIVED'
                                                  ? 'Not Received'
                                                  : rowData.status === 'IN_STOCK'
                                                  ? 'In Stock'
                                                  : rowData.status === 'COMPLETE'
                                                  ? 'Complete'
                                                  : rowData.status}
                                          </BodyCellText>
                                      </div>
                                  );
                              },
                          },
                ],
            },
        ];
    }, [isPalletView]);
};
