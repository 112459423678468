export const IMPORT_SETTINGS = {
    requireShippingPartner: {
        label: 'Require Shipping Partner',
        type: 'boolean',
        default: false,
    },
    defaultFreightType: {
        label: 'Default Freight Type',
        type: 'string',
        default: 'household',
        options: [
            { value: 'household', label: 'Household' },
            { value: 'pallet', label: 'Pallet' },
            { value: 'container', label: 'Container' },
        ],
    },
};

export const DRIVER_SETTINGS = {
    requiredDeliveryPhotos: {
        label: '# Required Delivery Photos',
        type: 'number',
        default: 0,
    },
    requiredDeliveryPhotosPerItem: {
        label: 'Require Photos per Item',
        type: 'boolean',
        default: false,
    },
};
